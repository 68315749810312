import React, { useState } from 'react'
import { TextInput, DateInput, RichInput, renderForm } from './GenericForm'
import * as CVTypes from '../types'
import ItemList from './ItemList'
import Switch from 'react-switch'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

const toTextLevel = (level: number) => {
  switch (level) {
    case 1:
      return 'Beginner'
    case 2:
      return 'Intermediate'
    case 3:
      return 'Proficient'
    case 4:
      return 'Advanced'
    case 5:
      return 'Expert'
    default:
      return 'Unspecified'
  }
}

const Entry: React.FC<{ entry: CVTypes.Skill }> = ({ entry }) => (
  <div>
    <div>{entry.title}</div>
    <div>
      {entry.level && <span className="text-muted skill-level-display">{toTextLevel(entry.level)}</span>}

      {!entry.isCurrent && entry.years && (
        <>
          <span className="text-muted">{entry.years} years</span>
        </>
      )}
      {entry.isCurrent && entry.start && (
        <>
          <span className="text-muted">{dayjs(new Date(entry.start.date)).fromNow(true)}</span>
        </>
      )}
    </div>
  </div>
)

const SkillLevel: React.FC<{ level: number; onChange: (_: number) => void }> = ({ level, onChange }) => (
  <select
    className="form-control"
    id="level"
    onChange={(e) => {
      onChange(parseInt(e.target.value))
    }}
  >
    {[0, 1, 2, 3, 4, 5].map((l, i) => (
      <option key={i} value={l} selected={level === l}>
        {toTextLevel(l)}
      </option>
    ))}
  </select>
)

const Form: React.FC<{ entry: CVTypes.Skill; onChange: (_?: CVTypes.Skill) => void }> = ({ entry, onChange }) => {
  const [years, setYears] = useState(entry.years === undefined ? '' : entry.years.toString())
  const isValidYears = years === '' || !isNaN(parseFloat(years))

  const yearsElement = entry.isCurrent
    ? {
        label: 'Start date',
        name: 'start',
        element: <DateInput value={entry.start} name={'start'} onChange={(start) => onChange({ ...entry, start })} />,
        span: 25 as 25,
      }
    : {
        label: 'Years practiced',
        name: 'years',
        element: (
          <TextInput
            value={years}
            name={'years'}
            invalid={!isValidYears}
            onChange={(y) => {
              setYears(y)
              const parsed = parseFloat(y)
              if (!isNaN(parsed)) {
                onChange({ ...entry, years: parsed })
              } else {
                onChange({ ...entry, years: undefined })
              }
            }}
          />
        ),
        span: 25 as 25,
      }

  return renderForm({
    fields: [
      [
        {
          label: 'Skill',
          name: 'title',
          element: <TextInput value={entry.title} name={'title'} onChange={(title) => onChange({ ...entry, title })} />,
        },
        {
          label: 'Level',
          name: 'level',
          element: (
            <SkillLevel
              level={entry.level ? entry.level : 0}
              onChange={(level) => {
                onChange({
                  ...entry,
                  level: level === 0 ? undefined : level,
                })
              }}
            />
          ),
        },
      ],
      [
        {
          label: 'Still using this skill?',
          name: 'is-skill-current',
          element: (
            <div className="current-skill-switch">
              <Switch onChange={() => onChange({ ...entry, isCurrent: !entry.isCurrent })} checked={entry.isCurrent} handleDiameter={32} height={36} width={80} />
            </div>
          ),
          span: 25 as 25,
        },
        yearsElement,
      ],
      [
        {
          label: 'Highlights',
          name: 'highlights',
          element: <RichInput value={entry.highlights} name={'highlights'} onChange={(highlights) => onChange({ ...entry, highlights })} />,
        },
      ],
    ],
  })
}

export const SkillList: React.FC<{ items?: CVTypes.Skill[]; onChange: (_?: CVTypes.Skill[]) => void }> = ({ items, onChange }) => (
  <ItemList
    formTitle="Skills"
    addTitle="Add skill"
    items={items}
    empty={{ title: '', id: '', isCurrent: true }}
    renderItem={(entry) => <Entry entry={entry} />}
    renderForm={(entry, onChange) => <Form entry={entry} onChange={onChange} />}
    onChange={onChange}
  />
)
