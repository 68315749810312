import React from 'react'
import { TextInput, DateInput, TagListInput, RichInput, renderForm } from './GenericForm'
import DateRange from './DateRange'
import * as CVTypes from '../types'
import ItemList from './ItemList'

const ExperienceEntry: React.FC<{ entry: CVTypes.WorkExperience }> = ({ entry }) => (
  <div>
    <div>
      {entry.position}
      {entry.position && entry.company ? ', ' : ''}
      {entry.company}
    </div>
    <DateRange start={entry.start} end={entry.end} />
    <div>
      <span className="text-muted">{entry.location}</span>
    </div>
  </div>
)

const ExperienceEntryForm: React.FC<{ entry: CVTypes.WorkExperience; onChange: (_?: CVTypes.WorkExperience) => void }> = ({ entry, onChange }) => {
  return renderForm({
    fields: [
      [
        { label: 'Position', name: 'position', element: <TextInput value={entry.position} name={'position'} onChange={(position) => onChange({ ...entry, position })} /> },
        { label: 'Company', name: 'company', element: <TextInput value={entry.company} name={'company'} onChange={(company) => onChange({ ...entry, company })} /> },
      ],
      [
        {
          label: 'Location',
          name: 'location',
          element: <TextInput value={entry.location} name={'location'} onChange={(location) => onChange({ ...entry, location })} />,
          span: 50 as 50,
        },
        { label: 'From', name: 'start', element: <DateInput value={entry.start} name={'start'} onChange={(start) => onChange({ ...entry, start })} /> },
        { label: 'To', name: 'end', element: <DateInput value={entry.end} name={'end'} onChange={(end) => onChange({ ...entry, end })} /> },
      ],
      [{ label: 'Skills used', name: 'skills', element: <TagListInput value={entry.skills} name={'skills'} onChange={(skills) => onChange({ ...entry, skills })} /> }],
      [
        {
          label: 'Description',
          name: 'description',
          element: <RichInput value={entry.description} name={'description'} onChange={(description) => onChange({ ...entry, description })} />,
        },
      ],
    ],
  })
}

export const ExperienceList: React.FC<{ items?: CVTypes.WorkExperience[]; onChange: (_?: CVTypes.WorkExperience[]) => void }> = ({ items, onChange }) => (
  <ItemList
    formTitle="Work Experience"
    addTitle="Add work experience"
    items={items}
    empty={{}}
    renderItem={(entry) => <ExperienceEntry entry={entry} />}
    renderForm={(entry, onChange) => <ExperienceEntryForm entry={entry} onChange={onChange} />}
    onChange={onChange}
  />
)
