import React from 'react'
import './landing-page.scss'
import { ReactComponent as Logo } from './resume.svg'
import { ReactComponent as TextLogo } from './text-logo.svg'
import logo from './resume.svg'

export const LandingPage = () => {
  return (
    <div className="bootstrap-theme landing-page">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="text-logo p-4">
              <TextLogo />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="image-holder col-12 col-md d-none d-lg-block">
            <div
              className="float-right"
              style={{
                backgroundImage: `url(${logo})`,
                width: '50%',
                paddingTop: '50%',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
              }}
            ></div>
          </div>
          <div className="col-12 col-md position-relative overflow-hidden">
            <div className="float-lg-left p-lg-5 mx-auto my-5 text-center text-lg-left">
              <h1 className="display-4 font-weight-normal">
                <span className="avo">Avo</span>
                <span className="cv">CV</span>
              </h1>
              <p className="lead font-weight-normal">
                Create a good-looking resume or a Curriculum Vitae by filling out a form and letting us do the hard work of making it look good and professional.
              </p>
              <a className="btn btn-primary btn-lg" href="/editor">
                Go to resume editor
              </a>
            </div>
          </div>
        </div>

        <div className="row justify-content-around">
          <div className="col-12 col-lg-4">
            <div className="my-3 p-3 text-center">
              <h2 className="display-5">How does it work?</h2>
            </div>
            <div className="mx-auto details-box">
              <p className="text-left">You fill out information about your skills, work history, education and other stuff you seem relevant for your job application.</p>
              <p className="text-left">
                We use one of our hand-crafted resume templates to generate a good-looking and properly aligned PDF ready to be sent to a prospective employer!
              </p>
              <p className="text-left">Our templates are designed to look good no matter how big or small your resume is.</p>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="my-3 py-3">
              <h2 className="display-5 text-center">Privacy</h2>
            </div>
            <div className="mx-auto details-box">
              <p className="text-left">
                Your resume can contain potentially private and sensitive information.
                <br />
                With AvoCV you are in complete control of your data. You can export your data as a file and store it on your computer.
                <br />
                You can import it again at any time you want to make changes to your resume.
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md text-center">
            <div>
              <Logo style={{ width: '24px', height: '24px' }} />
              <small className="px-3 mb-3 text-muted">&copy; AvoCV 2020</small>
            </div>
          </div>
          <div className="col-12 col-md text-center">
            <ul className="list-inline text-small">
              <li className="list-inline-item">
                <a className="text-muted" href="/privacy-policy">
                  Privacy Policy
                </a>
              </li>
              <li className="list-inline-item">
                <a className="text-muted" href="/terms-and-conditions">
                  Terms and Conditions
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
