import * as CVTypes from './types'

export interface LayoutEntry {
  id: string
  parent?: string
  isSection: boolean
}

export interface TemplateConfig {
  name: string
  firstPageLayout: LayoutEntry[]
  restPageLayout: LayoutEntry[]
  className: string
}

const configs = (sectionOrder: CVTypes.SectionOrderItem[]): Record<string, TemplateConfig> => ({
  'template-1': {
    name: 'Template No. 1',
    firstPageLayout: [
      { id: 'top', isSection: false },
      { id: 'bottom', isSection: false },
      { id: 'top-left', parent: 'top', isSection: false },
      { id: 'top-right', parent: 'top', isSection: false },
      { id: 'profile-picture', parent: 'top-left', isSection: true },
      { id: 'name', parent: 'top-left', isSection: true },
      { id: 'tagline', parent: 'top-left', isSection: true },
      { id: 'contact-details', parent: 'top-right', isSection: true },
      { id: 'profile-text', parent: 'bottom', isSection: true },
    ].concat(
      sectionOrder.map((oi) => ({
        id: oi.id,
        isSection: true,
        parent: 'bottom',
      })),
    ),
    restPageLayout: [{ id: 'all', isSection: false }].concat(
      sectionOrder.map((oi) => ({
        id: oi.id,
        isSection: true,
        parent: 'all',
      })),
    ),
    className: 'template-1',
  },
  'template-2': {
    name: 'Template No. 2',
    firstPageLayout: [
      { id: 'left', isSection: false },
      { id: 'right', isSection: false },
      { id: 'personal-box', isSection: false, parent: 'left' },
      { id: 'left-rest', isSection: false, parent: 'left' },
      { id: 'profile-picture', parent: 'personal-box', isSection: true },
      { id: 'name', parent: 'personal-box', isSection: true },
      { id: 'tagline', parent: 'personal-box', isSection: true },
      { id: 'contact-details', parent: 'left-rest', isSection: true },
      { id: 'skills', parent: 'left-rest', isSection: true },
      { id: 'profile-text', parent: 'right', isSection: true },
    ].concat(
      sectionOrder
        .filter((oi) => oi.id !== 'skills')
        .map((oi) => ({
          id: oi.id,
          isSection: true,
          parent: 'right',
        })),
    ),
    restPageLayout: [{ id: 'all', isSection: false }].concat(
      sectionOrder.map((oi) => ({
        id: oi.id,
        isSection: true,
        parent: 'all',
      })),
    ),
    className: 'template-2',
  },
  'template-3': {
    name: 'Template No. 3',
    firstPageLayout: [
      { id: 'top', isSection: false },
      { id: 'top-picture', parent: 'top', isSection: false },
      { id: 'top-name', parent: 'top', isSection: false },
      { id: 'bottom', isSection: false },
      { id: 'left', parent: 'bottom', isSection: false },
      { id: 'right', parent: 'bottom', isSection: false },
      { id: 'profile-picture', parent: 'top-picture', isSection: true },
      { id: 'name', parent: 'top-name', isSection: true },
      { id: 'tagline', parent: 'top-name', isSection: true },
      { id: 'profile-text', parent: 'left', isSection: true },
      { id: 'contact-details', parent: 'left', isSection: true },
      { id: 'skills', parent: 'left', isSection: true },
    ].concat(
      sectionOrder
        .filter((oi) => oi.id !== 'skills')
        .map((oi) => ({
          id: oi.id,
          isSection: true,
          parent: 'right',
        })),
    ),
    restPageLayout: [{ id: 'all', isSection: false }].concat(
      sectionOrder.map((oi) => ({
        id: oi.id,
        isSection: true,
        parent: 'all',
      })),
    ),
    className: 'template-3',
  },
  'template-4': {
    name: 'Template No. 4',
    firstPageLayout: [
      { id: 'top', isSection: false },
      { id: 'bottom', isSection: false },
      { id: 'top-left', parent: 'top', isSection: false },
      { id: 'top-right', parent: 'top', isSection: false },
      { id: 'profile-picture', parent: 'top-left', isSection: true },
      { id: 'name', parent: 'top-left', isSection: true },
      { id: 'tagline', parent: 'top-left', isSection: true },
      { id: 'contact-details', parent: 'top-right', isSection: true },
      { id: 'profile-text', parent: 'bottom', isSection: true },
    ].concat(
      sectionOrder.map((oi) => ({
        id: oi.id,
        isSection: true,
        parent: 'bottom',
      })),
    ),
    restPageLayout: [{ id: 'all', isSection: false }].concat(
      sectionOrder.map((oi) => ({
        id: oi.id,
        isSection: true,
        parent: 'all',
      })),
    ),
    className: 'template-4',
  },
})

export const getTemplateConfig = (id: string, sectionOrder: CVTypes.SectionOrderItem[]): TemplateConfig => {
  return configs(sectionOrder)[id]
}

const ids = Object.keys(configs([]))

export const templateList: {
  id: string
  name: string
}[] = ids.map((id) => ({
  id,
  name: configs([])[id].name,
}))
