import React, { useState, useEffect, useLayoutEffect, useRef, useReducer, ReactElement, CSSProperties } from 'react'
import 'typeface-open-sans'
import 'typeface-overpass-mono'
import './print.css'
import './templates/template-1.scss'
import './templates/template-2.scss'
import './templates/template-3.scss'
import './templates/template-4.scss'
import * as CVTypes from './types'
import { CustomSection } from './render/customSection'
import { SkillsSection, SkillHighlightsSection } from './render/skillsSection'
import { EducationSection } from './render/educationSection'
import { ExperienceSection } from './render/experienceSection'
import { PublicationsSection } from './render/publicationsSection'
import { ProfilePicture, ContactDetails, Name, Tagline, ProfileText } from './render/header'
import { LayoutEntry, getTemplateConfig } from './templateConfigs'
//https://creativetacos.com/free-creative-resume-psd-template/

type SectionToRender =
  | {
      type: 'profile-picture'
      id: string
      profilePicture?: string
    }
  | {
      type: 'profile-text'
      id: string
      profile?: string
    }
  | {
      type: 'contact-details'
      id: string
      contact?: CVTypes.ContactInfo
    }
  | {
      type: 'name'
      id: string
      name?: string
    }
  | {
      type: 'tagline'
      id: string
      tagline?: string
    }
  | {
      type: 'skills'
      id: string
      renderCount: number
      skills: CVTypes.Skill[]
    }
  | {
      type: 'skill-highlights'
      id: string
      renderCount: number
      skills: CVTypes.Skill[]
    }
  | {
      type: 'experience'
      id: string
      renderCount: number
      experience: CVTypes.WorkExperience[]
    }
  | {
      type: 'publications'
      id: string
      renderCount: number
      publications: CVTypes.Publication[]
    }
  | {
      type: 'education'
      id: string
      renderCount: number
      education: CVTypes.Education[]
    }
  | {
      type: 'custom-section'
      id: string
      renderCount: number
      section: CVTypes.CustomSection
    }

interface RenderedSection {
  height: number
  hasRemaining: boolean
}

interface PageState {
  pageLayout: LayoutEntry[]
  pageHeight: number
  renderedSections: Record<string, RenderedSection>
  finishedLayoutEntries: string[]
  containerAvailableHeights: Record<string, number>
  nextPageSections: SectionToRender[]
}

type PageAction =
  | {
      type: 'item-rendered'
      id: string
      height: number
      hasRemaining: boolean
      nextPageSections: SectionToRender[]
    }
  | {
      type: 'empty-layout'
      id: string
    }
  | {
      type: 'set-page-height'
      height: number
    }
  | {
      type: 'set-container-available-height'
      id: string
      height: number
    }

const findSiblings = (entry: LayoutEntry, layout: LayoutEntry[]): LayoutEntry[] => layout.filter((le) => le.parent === entry.parent && le.id !== entry.id)
const findParent = (entry: LayoutEntry, layout: LayoutEntry[]): LayoutEntry => layout.find((le) => le.id === entry.parent) as LayoutEntry
const findLayoutEntryById = (id: string, layout: LayoutEntry[]): LayoutEntry => layout.find((le) => le.id === id) as LayoutEntry

const findFinishedParents = (entry: LayoutEntry, layout: LayoutEntry[], finishedLayoutEntries: string[]): string[] => {
  if (!entry.parent) {
    return []
  }

  const siblings = findSiblings(entry, layout)
  const finishedSiblings = siblings.filter((s) => finishedLayoutEntries.includes(s.id))

  if (siblings.length === finishedSiblings.length) {
    const parent = findParent(entry, layout)
    const updatedFinished = finishedLayoutEntries.concat([parent.id])
    return findFinishedParents(parent, layout, updatedFinished).concat([parent.id])
  }

  return []
}

const findFinishedLayout = (
  finishedId: string,
  hasRemaining: boolean,
  renderedSections: Record<string, RenderedSection>,
  pageLayout: LayoutEntry[],
  finishedLayout: string[],
): string[] => {
  const layoutEntry = findLayoutEntryById(finishedId, pageLayout)
  if (!layoutEntry.parent) {
    return []
  }

  if (hasRemaining) {
    return [layoutEntry.parent].concat(findFinishedParents(findParent(layoutEntry, pageLayout), pageLayout, finishedLayout.concat([layoutEntry.parent])))
  }

  const siblings = findSiblings(layoutEntry, pageLayout)
  const finishedSiblings = siblings.map((s) => renderedSections[s.id]).filter((r) => r !== undefined)

  if (siblings.length === finishedSiblings.length) {
    return [layoutEntry.parent].concat(findFinishedParents(findParent(layoutEntry, pageLayout), pageLayout, finishedLayout.concat([layoutEntry.parent])))
  }

  return []
}

const findFinishedLayoutForEmptyLayout = (finishedId: string, pageLayout: LayoutEntry[], finishedLayout: string[]): string[] => {
  const layoutEntry = findLayoutEntryById(finishedId, pageLayout)
  if (!layoutEntry.parent) {
    return [layoutEntry.id]
  }

  return [layoutEntry.id].concat(findFinishedParents(layoutEntry, pageLayout, finishedLayout))
}

function pageReducer(state: PageState, action: PageAction): PageState {
  switch (action.type) {
    case 'item-rendered':
      const renderedSections = state.renderedSections
      renderedSections[action.id] = {
        height: action.height,
        hasRemaining: action.hasRemaining,
      }

      const newFinishedLayoutEntries = findFinishedLayout(action.id, action.hasRemaining, state.renderedSections, state.pageLayout, state.finishedLayoutEntries)

      return { ...state, renderedSections, finishedLayoutEntries: state.finishedLayoutEntries.concat(newFinishedLayoutEntries), nextPageSections: action.nextPageSections }
    case 'empty-layout':
      const newFinishedEmptyEntries = findFinishedLayoutForEmptyLayout(action.id, state.pageLayout, state.finishedLayoutEntries)

      return { ...state, finishedLayoutEntries: state.finishedLayoutEntries.concat(newFinishedEmptyEntries) }
    case 'set-page-height':
      return { ...state, pageHeight: action.height }
    case 'set-container-available-height':
      const containerAvailableHeights = state.containerAvailableHeights
      containerAvailableHeights[action.id] = action.height
      return { ...state, containerAvailableHeights }
  }
}

const skillsFilter = (skills: CVTypes.Skill[], ids: string[]) => ids.map((id) => skills.find((s) => s.id === id)).filter((s) => s !== undefined) as CVTypes.Skill[]

const cvToSections = (c: CVTypes.CV, settings: CVTypes.TemplateSettings): SectionToRender[] =>
  [
    [
      {
        type: 'profile-picture' as 'profile-picture',
        id: 'profile-picture',
        profilePicture: c.personalDetails?.profileImage,
      },
    ],
    [
      {
        type: 'name' as 'name',
        id: 'name',
        name: c.personalDetails?.contact?.fullName,
      },
    ],
    [
      {
        type: 'tagline' as 'tagline',
        id: 'tagline',
        tagline: c.personalDetails?.jobTitle,
      },
    ],
    [
      {
        type: 'profile-text' as 'profile-text',
        id: 'profile-text',
        profile: c.profile,
      },
    ],
    [
      {
        type: 'contact-details' as 'contact-details',
        id: 'contact-details',
        contact: c.personalDetails?.contact,
      },
    ],
    c.skills
      ? [
          {
            type: 'skills' as 'skills',
            id: 'skills',
            skills: settings.coreSkills ? skillsFilter(c.skills, settings.coreSkills) : c.skills,
            renderCount: 0,
          },
        ]
      : [],
    c.skills
      ? [
          {
            type: 'skill-highlights' as 'skill-highlights',
            id: 'skill-highlights',
            skills: settings.highlightedSkills
              ? skillsFilter(c.skills, settings.highlightedSkills)
              : c.skills.filter((s) => (s.highlights ? s.highlights.trim().replace(/[\u200B-\u200D\uFEFF]/g, '').length > 0 : false)),
            renderCount: 0,
          },
        ]
      : [],
    c.experience
      ? [
          {
            type: 'experience' as 'experience',
            id: 'experience',
            experience: c.experience,
            renderCount: 0,
          },
        ]
      : [],
    c.publications
      ? [
          {
            type: 'publications' as 'publications',
            id: 'publications',
            publications: c.publications,
            renderCount: 0,
          },
        ]
      : [],
    c.education
      ? [
          {
            type: 'education' as 'education',
            id: 'education',
            education: c.education,
            renderCount: 0,
          },
        ]
      : [],
    c.customSections
      ? c.customSections.map((section) => ({
          type: 'custom-section' as 'custom-section',
          id: section.id,
          section,
          renderCount: 0,
        }))
      : [],
  ].flat()

const renderSection = (state: PageState, dispatch: React.Dispatch<PageAction>, sectionType: SectionToRender, availableHeight: number): React.ReactElement => {
  switch (sectionType.type) {
    case 'profile-picture':
      return (
        <ProfilePicture
          key={sectionType.id}
          profilePicture={sectionType.profilePicture}
          onRenderFinished={(height) => {
            dispatch({ type: 'item-rendered', id: sectionType.id, height, hasRemaining: false, nextPageSections: state.nextPageSections })
          }}
        />
      )
    case 'name':
      return (
        <Name
          key={sectionType.id}
          name={sectionType.name}
          onRenderFinished={(height) => {
            dispatch({ type: 'item-rendered', id: sectionType.id, height, hasRemaining: false, nextPageSections: state.nextPageSections })
          }}
        />
      )
    case 'tagline':
      return (
        <Tagline
          key={sectionType.id}
          tagline={sectionType.tagline}
          onRenderFinished={(height) => {
            dispatch({ type: 'item-rendered', id: sectionType.id, height, hasRemaining: false, nextPageSections: state.nextPageSections })
          }}
        />
      )
    case 'profile-text':
      return (
        <ProfileText
          key={sectionType.id}
          profile={sectionType.profile}
          onRenderFinished={(height) => {
            dispatch({ type: 'item-rendered', id: sectionType.id, height, hasRemaining: false, nextPageSections: state.nextPageSections })
          }}
        />
      )
    case 'contact-details':
      return (
        <ContactDetails
          key={sectionType.id}
          contact={sectionType.contact}
          onRenderFinished={(height) => {
            dispatch({ type: 'item-rendered', id: sectionType.id, height, hasRemaining: false, nextPageSections: state.nextPageSections })
          }}
        />
      )
    case 'skills':
      return (
        <SkillsSection
          key={sectionType.id}
          items={sectionType.skills}
          availableHeight={availableHeight}
          renderCount={sectionType.renderCount}
          onRenderFinished={(remaining, height) => {
            const sections = state.nextPageSections.slice()
            const nextPageSections =
              remaining !== undefined
                ? sections.concat([
                    {
                      type: sectionType.type,
                      id: sectionType.id,
                      skills: remaining,
                      renderCount: JSON.stringify(remaining) !== JSON.stringify(sectionType.skills) ? sectionType.renderCount + 1 : sectionType.renderCount,
                    },
                  ])
                : sections

            dispatch({ type: 'item-rendered', id: sectionType.id, height, hasRemaining: remaining !== undefined, nextPageSections })
          }}
        />
      )
    case 'skill-highlights':
      return (
        <SkillHighlightsSection
          key={sectionType.id}
          items={sectionType.skills}
          availableHeight={availableHeight}
          renderCount={sectionType.renderCount}
          onRenderFinished={(remaining, height) => {
            const sections = state.nextPageSections.slice()
            const nextPageSections =
              remaining !== undefined
                ? sections.concat([
                    {
                      type: sectionType.type,
                      id: sectionType.id,
                      skills: remaining,
                      renderCount: JSON.stringify(remaining) !== JSON.stringify(sectionType.skills) ? sectionType.renderCount + 1 : sectionType.renderCount,
                    },
                  ])
                : sections

            dispatch({ type: 'item-rendered', id: sectionType.id, height, hasRemaining: remaining !== undefined, nextPageSections })
          }}
        />
      )
    case 'experience':
      return (
        <ExperienceSection
          key={sectionType.id}
          items={sectionType.experience}
          availableHeight={availableHeight}
          renderCount={sectionType.renderCount}
          onRenderFinished={(remaining, height) => {
            const sections = state.nextPageSections.slice()
            const nextPageSections =
              remaining !== undefined
                ? sections.concat([
                    {
                      type: sectionType.type,
                      id: sectionType.id,
                      experience: remaining,
                      renderCount: JSON.stringify(remaining) !== JSON.stringify(sectionType.experience) ? sectionType.renderCount + 1 : sectionType.renderCount,
                    },
                  ])
                : sections

            dispatch({ type: 'item-rendered', id: sectionType.id, height, hasRemaining: remaining !== undefined, nextPageSections })
          }}
        />
      )
    case 'publications':
      return (
        <PublicationsSection
          key={sectionType.id}
          items={sectionType.publications}
          availableHeight={availableHeight}
          renderCount={sectionType.renderCount}
          onRenderFinished={(remaining, height) => {
            const sections = state.nextPageSections.slice()
            const nextPageSections =
              remaining !== undefined
                ? sections.concat([
                    {
                      type: sectionType.type,
                      id: sectionType.id,
                      publications: remaining,
                      renderCount: JSON.stringify(remaining) !== JSON.stringify(sectionType.publications) ? sectionType.renderCount + 1 : sectionType.renderCount,
                    },
                  ])
                : sections

            dispatch({ type: 'item-rendered', id: sectionType.id, height, hasRemaining: remaining !== undefined, nextPageSections })
          }}
        />
      )
    case 'education':
      return (
        <EducationSection
          key={sectionType.id}
          items={sectionType.education}
          availableHeight={availableHeight}
          renderCount={sectionType.renderCount}
          onRenderFinished={(remaining, height) => {
            const sections = state.nextPageSections.slice()
            const nextPageSections =
              remaining !== undefined
                ? sections.concat([
                    {
                      type: sectionType.type,
                      id: sectionType.id,
                      education: remaining,
                      renderCount: JSON.stringify(remaining) !== JSON.stringify(sectionType.education) ? sectionType.renderCount + 1 : sectionType.renderCount,
                    },
                  ])
                : sections

            dispatch({ type: 'item-rendered', id: sectionType.id, height, hasRemaining: remaining !== undefined, nextPageSections })
          }}
        />
      )
    case 'custom-section':
      return (
        <CustomSection
          key={sectionType.id}
          section={sectionType.section}
          availableHeight={availableHeight}
          renderCount={sectionType.renderCount}
          onRenderFinished={(remaining, height) => {
            const sections = state.nextPageSections.slice()
            const nextPageSections =
              remaining !== undefined
                ? sections.concat([
                    {
                      type: sectionType.type,
                      id: sectionType.id,
                      section: remaining,
                      renderCount: JSON.stringify(remaining) !== JSON.stringify(sectionType.section) ? sectionType.renderCount + 1 : sectionType.renderCount,
                    },
                  ])
                : sections

            dispatch({ type: 'item-rendered', id: sectionType.id, height, hasRemaining: remaining !== undefined, nextPageSections })
          }}
        />
      )
  }
}

const findChildren = (layout: LayoutEntry[], id: string): LayoutEntry[] => layout.filter((e) => e.parent === id)

const findSectionDependencies = (layout: LayoutEntry[], entry: LayoutEntry): LayoutEntry[] => {
  const sameParent = layout.filter((e) => e.parent === entry.parent)
  const entryPosition = sameParent.findIndex((e) => e.id === entry.id)
  return sameParent.slice(0, entryPosition)
}

const findLayoutEntryDescendants = (layout: LayoutEntry[], entry: LayoutEntry): LayoutEntry[] => {
  const children = findChildren(layout, entry.id)
  const sectionChildren = children.filter((c) => c.isSection === true)

  if (children.length === sectionChildren.length) {
    return sectionChildren
  }

  return sectionChildren.concat(children.filter((c) => c.isSection === false).flatMap((e) => findLayoutEntryDescendants(layout, e)))
}

const findDirectSections = (layout: LayoutEntry[], entry: LayoutEntry): LayoutEntry[] => {
  const children = findChildren(layout, entry.id)
  return children.filter((c) => c.isSection === true)
}

const renderEntry = (str: SectionToRender[], layout: LayoutEntry[], entry: LayoutEntry, state: PageState, dispatch: React.Dispatch<PageAction>): ReactElement | null => {
  if (entry.isSection && entry.parent) {
    const deps = findSectionDependencies(layout, entry)
    //    const availableHeightDeps = availableHeightDependencies(layout, entry)
    const availableHeight = state.containerAvailableHeights[entry.parent]

    /*  
    console.log(`rendering section ${entry.id}, parent is ${entry.parent}, 
    available height: ${availableHeight}
    needs to wait for ${deps.map(d => d.id).join(',')}`)
*/
    const renderedDeps = deps.map((d) => state.renderedSections[d.id]).filter((r) => r !== undefined && !r.hasRemaining)

    if (renderedDeps.length === deps.length) {
      const sectionAvHeight = availableHeight - renderedDeps.map((r) => (r.height ? r.height : 0)).reduce((a, b) => a + b, 0)
      //  console.log(`All dependencies for ${entry.id} are rendered`)
      const s = str.find((ss) => ss.id === entry.id)

      return s ? renderSection(state, dispatch, s, sectionAvHeight) : <div>Section was not found {entry.id}</div>
    }

    return null
  }

  const deps = findSectionDependencies(layout, entry)
  const renderedDeps = deps.filter((layoutDep) => {
    const allChildren = findLayoutEntryDescendants(layout, layoutDep)
    const renderedChildren = allChildren.map((d) => state.renderedSections[d.id]).filter((r) => r !== undefined)
    return allChildren.length === renderedChildren.length
  })
  const layoutReadyToRender = deps.length === renderedDeps.length

  /*  
  const sectionsToRender = findLayoutEntryDescendants(layout, entry)
  console.log(`Rendering wrapper div-${entry.id}, 
    it needs to wait for ${deps.map(d => d.id).join(',')}
    rendered dependencies ${renderedDeps.map(d => d.id).join(',')}
    it's ready to render ${layoutReadyToRender}
    it has sections to render ${sectionsToRender.map(d => d.id).join(',')} 
    `)
*/

  if (layoutReadyToRender) {
    return <LayoutFragment key={entry.id} entry={entry} layout={layout} state={state} dispatch={dispatch} sectionsToRender={str} />
  }

  return null
}

const LayoutFragment: React.FC<{
  layout: LayoutEntry[]
  entry: LayoutEntry
  state: PageState
  dispatch: React.Dispatch<PageAction>
  sectionsToRender: SectionToRender[]
}> = ({ layout, entry, state, dispatch, sectionsToRender }) => {
  const [availableHeight, setAvailableHeight] = useState<number | undefined>(undefined)

  const ref = useRef<HTMLDivElement>(null)

  const isLayoutContainer = findDirectSections(layout, entry).length === 0
  //  const allChildren = findLayoutEntryDescendants()
  const allChildrenRendered = state.finishedLayoutEntries.includes(entry.id)

  //  console.log(`all children rendered ${entry.id} ${allChildrenRendered}`)

  useLayoutEffect(() => {
    requestAnimationFrame(() => {
      const height = ref.current ? Math.round(ref.current.getBoundingClientRect().height) : 0
      setAvailableHeight(height)
      console.log(`available height for '${entry.id}' is ${height}`)
      if (!isLayoutContainer) {
        dispatch({ type: 'set-container-available-height', id: entry.id, height: height })
      }
    })
  }, [])

  //  console.log(`${entry.id} is layout container ${isLayoutContainer} ${findDirectSections(layout, entry).map(s => s.id).join(',')}`)

  const wrapperStyle: CSSProperties =
    (isLayoutContainer && !allChildrenRendered) || availableHeight === undefined
      ? {
          height: '100%',
          boxSizing: 'border-box',
        }
      : {
          boxSizing: 'border-box',
        }

  const children = findChildren(layout, entry.id)

  useEffect(() => {
    if (isLayoutContainer && children.length === 0) {
      console.log(`empty layout detected ${entry.id}`)
      dispatch({ type: 'empty-layout', id: entry.id })
    }
  }, [JSON.stringify(children)])

  if (isLayoutContainer) {
    //    console.log(`children count for ${entry.id} ${children.length}`)
    return (
      <div style={wrapperStyle} className={entry.id} key={entry.id}>
        {children.map((child) => renderEntry(sectionsToRender, layout, child, state, dispatch))}
      </div>
    )
  } else {
    if (availableHeight === undefined) {
      return (
        <div className={entry.id} key={entry.id} style={wrapperStyle}>
          <div
            ref={ref}
            style={{
              height: '100%',
              backgroundColor: 'red',
            }}
          >
            height test
          </div>
        </div>
      )
    }

    return (
      <div style={wrapperStyle} className={entry.id} key={entry.id}>
        {children.map((child) => renderEntry(sectionsToRender, layout, child, state, dispatch))}
      </div>
    )
  }
}

const Page: React.FC<{ pageLayout: LayoutEntry[]; sections: SectionToRender[]; index: number; isLast: boolean; onRenderFinished: (sections: SectionToRender[]) => void }> = ({
  pageLayout,
  sections,
  index,
  isLast,
  onRenderFinished,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  const [state, dispatch] = useReducer(pageReducer, {
    pageLayout,
    finishedLayoutEntries: [],
    pageHeight: 0,
    renderedSections: {},
    containerAvailableHeights: {},
    nextPageSections: [],
  })

  useEffect(() => {
    requestAnimationFrame(() => {
      const height = ref.current ? Math.round(ref.current.getBoundingClientRect().height) : 0
      console.log(`first page height: ${height}`)
      dispatch({ type: 'set-page-height', height })
    })
  }, [])

  const layoutElements = pageLayout.filter((le) => !le.isSection)
  const finishedLayoutElements = layoutElements.filter((le) => state.finishedLayoutEntries.includes(le.id))

  const finished = layoutElements.length === finishedLayoutElements.length
  useEffect(() => {
    if (finished === true) {
      console.log(`page finished`)
      const remainingSections = state.nextPageSections
      const sectionsToRender = sections.filter((s) => state.renderedSections[s.id] === undefined)
      console.log(remainingSections)
      console.log(sectionsToRender)
      console.log(state)
      const nextPageSections: SectionToRender[] = sectionsToRender.concat(remainingSections) //state.nextPageSections.concat(sections.slice(state.items.length))
      onRenderFinished(nextPageSections)
    }
  }, [finished])

  const containerClassName = isLast ? 'container' : 'container not-last'

  const result = pageLayout.filter((e) => !e.parent).map((entry) => renderEntry(sections, pageLayout, entry, state, dispatch))

  return (
    <div className="sheet">
      <div className="sheet-content" ref={ref}>
        <div className={`${containerClassName} container-${index}`}>{result}</div>
      </div>
    </div>
  )
}

const cleanPageLayout = (sections: SectionToRender[], layout: LayoutEntry[]) => layout.filter((le) => !le.isSection || sections.find((s) => s.id === le.id) !== undefined)

const Template: React.FC<{ cv: CVTypes.CV; settings: CVTypes.TemplateSettings }> = ({ cv, settings }) => {
  const [pages, setPages] = useState<SectionToRender[][]>([cvToSections(cv, settings)])

  const [finished, setFinished] = useState(false)

  useEffect(() => {
    console.log(`Current pages`)
    console.log(pages)
  }, [pages.length])

  const templateConfig = getTemplateConfig(settings.templateId, settings.sectionOrder)

  return (
    <div className={templateConfig.className}>
      {pages.map((sections, i) => (
        <Page
          isLast={i === pages.length - 1}
          key={i}
          index={i}
          pageLayout={i === 0 ? cleanPageLayout(sections, templateConfig.firstPageLayout) : cleanPageLayout(sections, templateConfig.restPageLayout)}
          sections={sections}
          onRenderFinished={(nextPageSections) => {
            console.log(`page ${i} render finished`)
            console.log(nextPageSections)

            if (nextPageSections.length > 0 && pages.length < 20) {
              setPages(pages.concat([nextPageSections]))
            } else {
              console.log('all finished')
              setFinished(true)
            }
          }}
        />
      ))}
      {finished && <div id="all-finished"></div>}
    </div>
  )
}

export default Template
