import React from 'react'
import * as CVTypes from '../types'
import { flexibleSection, removeExcessArray, canRemoveExcessArray, getRemainingArray } from './flexibleSection'
import ReactMarkdown from 'react-markdown'
import { DateRange } from '../editor/DateRange'
import { SectionTitle } from './SectionTitle'

const WorkRow: React.FC<{ experience: CVTypes.WorkExperience }> = ({ experience }) => (
  <div className="experience-entry">
    <div className="position">{experience.position}</div>
    <div className="experience-date">
      <DateRange start={experience.start} end={experience.end} />
    </div>
    {experience.skills && (
      <div className="experience-skills">
        {experience.skills.map((s, i) => (
          <span className="experience-skill" key={i}>
            {s}
          </span>
        ))}
      </div>
    )}
    {experience.description && (
      <div className="description markdown">
        <ReactMarkdown source={experience.description} />
      </div>
    )}
  </div>
)

const RawExperienceSection: React.FC<{ experience: CVTypes.WorkExperience[]; renderCount: number }> = ({ experience, renderCount }) => (
  <div className="experience">
    {renderCount === 0 && <SectionTitle title="Work Experience" />}
    {experience.map((e, i) => (
      <WorkRow key={i} experience={e} />
    ))}
  </div>
)

export const ExperienceSection = flexibleSection<CVTypes.WorkExperience[]>(
  (e, rc) => e.length > 0 && <RawExperienceSection renderCount={rc} experience={e} />,
  removeExcessArray,
  canRemoveExcessArray,
  getRemainingArray,
  'experience',
)
