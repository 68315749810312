import React from 'react'
import { templateList } from '../templateConfigs'
import { MdClose } from 'react-icons/md'

export const TemplatePicker: React.FC<{
  selected: string
  onPick: (_: string) => void
  onCancel: () => void
}> = ({ selected, onPick, onCancel }) => {
  return (
    <div className="template-list">
      <div className="template-picker-header">
        <div className="close-button">
          <MdClose onClick={onCancel} />
        </div>
      </div>
      <div className="template-entries">
        {templateList.map((t, i) => (
          <div className={`template-entry ${t.id === selected ? 'selected' : ''}`} key={i} onClick={() => onPick(t.id)}>
            <div
              className="template-thumbnail"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/templates/${t.id}-thumbnail.png)`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
              }}
            ></div>
            <div className="template-name">{t.name}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
