import React, { useState, ReactElement } from 'react'
import { MdChevronLeft, MdDelete } from 'react-icons/md'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import { reorder } from './reorder'

const Item: <T>(props: {
  entry: T
  renderItem: (_: T) => ReactElement
  renderForm: (_: T, onChange: (_?: T | undefined) => void) => ReactElement
  index: number
  isEditing: boolean
  isDeleting: boolean
  setEditing: (_: number | undefined) => void
  setDeleting: (_: number | undefined) => void
  onChange: (_?: T | undefined) => void
  onDelete: (_: number) => void
  canDelete: (_: T) => boolean
}) => ReactElement = ({ entry, renderItem, renderForm, index, isEditing, isDeleting, setEditing, setDeleting, onChange, onDelete, canDelete }) => {
  const header = (
    <div
      className="list-item-header"
      onClick={() => {
        if (isEditing) {
          setEditing(undefined)
        } else {
          setEditing(index)
        }
      }}
    >
      <div className="list-item-summary">{renderItem(entry)}</div>
      <div className="list-item-controls">
        <MdChevronLeft className={isEditing ? 'expanded' : ''} />
        <MdDelete
          className={'delete-item'}
          onClick={(e) => {
            if (canDelete(entry)) {
              onDelete(index)
            } else {
              setDeleting(index)
            }
            e.stopPropagation()
          }}
        />
      </div>
    </div>
  )

  return (
    <>
      {header}
      {isEditing && renderForm(entry, onChange)}
      {isDeleting && (
        <div className="delete-confirmation">
          <span>Are you sure you want to delete this item?</span>
          <div className="delete-buttons">
            <button
              type="button"
              className="btn btn-warning"
              onClick={() => {
                setDeleting(undefined)
                onDelete(index)
              }}
            >
              Confirm
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setDeleting(undefined)
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </>
  )
}

const DraggableItem: <T>(props: {
  entry: T
  renderItem: (_: T) => ReactElement
  renderForm: (_: T, onChange: (_?: T | undefined) => void) => ReactElement
  index: number
  isEditing: boolean
  isDeleting: boolean
  onChange: (_?: T | undefined) => void
  onDelete: (_: number) => void
  setEditing: (_: number | undefined) => void
  setDeleting: (_: number | undefined) => void
  canDelete: (_: T) => boolean
}) => ReactElement = ({ entry, renderItem, renderForm, index, isEditing, isDeleting, onChange, onDelete, setEditing, setDeleting, canDelete }) => (
  <Draggable draggableId={`${index}`} index={index}>
    {(provided, snapshot) => (
      <div ref={provided.innerRef} {...provided.draggableProps} className={snapshot.isDragging ? 'dragging' : ''}>
        <div className="list-item">
          <div className={`drag-handle ${isEditing || isDeleting ? 'hidden' : ''}`} {...provided.dragHandleProps}>
            <span>::</span>
          </div>
          <Item
            entry={entry}
            renderItem={renderItem}
            renderForm={renderForm}
            index={index}
            isEditing={isEditing}
            isDeleting={isDeleting}
            onChange={onChange}
            onDelete={onDelete}
            setEditing={setEditing}
            setDeleting={setDeleting}
            canDelete={canDelete}
          />
        </div>
      </div>
    )}
  </Draggable>
)

const DroppableList: <T>(props: {
  items: T[]
  renderItem: (_: T) => ReactElement
  renderForm: (_: T, onChange: (_?: T | undefined) => void) => ReactElement
  deletingIndex: number | undefined
  editingIndex: number | undefined
  onChange: (index: number, _?: T | undefined) => void
  onDelete: (_: number) => void
  setEditing: (_: number | undefined) => void
  setDeleting: (_: number | undefined) => void
  canDelete: (_: T) => boolean
}) => ReactElement = ({ items, renderItem, renderForm, deletingIndex, editingIndex, onChange, onDelete, setEditing, setDeleting, canDelete }) => (
  <Droppable droppableId="list">
    {(provided) => (
      <div ref={provided.innerRef} {...provided.droppableProps} className="list">
        {items.map((e, i) => (
          <DraggableItem
            key={i}
            index={i}
            entry={e}
            renderItem={renderItem}
            renderForm={renderForm}
            isEditing={editingIndex === i}
            isDeleting={deletingIndex === i}
            onChange={(updated) => onChange(i, updated)}
            onDelete={onDelete}
            setEditing={setEditing}
            setDeleting={setDeleting}
            canDelete={canDelete}
          />
        ))}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
)

export const ItemList: <T>(props: {
  customHeader?: ReactElement
  formTitle?: string
  addTitle: string
  items?: T[]
  empty: T
  renderItem: (_: T) => ReactElement
  renderForm: (_: T, onChange: (_?: T | undefined) => void) => ReactElement
  onChange: (_?: T[]) => void
}) => ReactElement = ({ customHeader, formTitle, addTitle, items, empty, renderItem, renderForm, onChange }) => {
  const [editing, setEditing] = useState<number | undefined>(undefined)
  const [deleting, setDeleting] = useState<number | undefined>(undefined)

  function onDragEnd(result: DropResult) {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const updatedItems = items ? reorder(items, result.source.index, result.destination.index) : undefined

    onChange(updatedItems)
  }

  const [addButton, setAddButton] = useState<HTMLElement | null>(null)

  return (
    <div>
      {customHeader}
      {!customHeader && <h5>{formTitle ? formTitle : <span className="text-muted">Untitled</span>}</h5>}
      <div>
        <button
          ref={setAddButton}
          type="button"
          className="btn btn-link"
          onClick={() => {
            onChange(items ? [empty].concat(items) : [empty])
            setEditing(0)
            if (addButton) {
              addButton.blur()
            }
          }}
        >
          {addTitle}
        </button>
      </div>
      {items && (
        <DragDropContext onDragEnd={onDragEnd}>
          <DroppableList
            items={items}
            renderItem={renderItem}
            renderForm={renderForm}
            deletingIndex={deleting}
            editingIndex={editing}
            onChange={(index, updated) => {
              if (items && updated) {
                const updatedItems = items.slice()
                updatedItems[index] = updated
                onChange(updatedItems)
              }
            }}
            onDelete={(index) => {
              if (items) {
                onChange(items.filter((_, i) => i !== index))
              }
            }}
            canDelete={(item) => JSON.stringify(item) === JSON.stringify(empty)}
            setEditing={setEditing}
            setDeleting={setDeleting}
          />
        </DragDropContext>
      )}
    </div>
  )
}

export default ItemList
