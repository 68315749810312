import React from 'react'
import * as CVTypes from '../types'
import { flexibleSection, removeExcessArray } from './flexibleSection'
import ReactMarkdown from 'react-markdown'
import { DateRange } from '../editor/DateRange'
import isEmptyContent from './isEmptyContent'

const CustomItemRow: React.FC<{ item: CVTypes.CustomItem }> = ({ item }) => (
  <div className="custom-item">
    {(item.title || item.start || item.end || item.location) && (
      <div className="custom-item-header">
        <div className="custom-item-title">{item.title}</div>
        <div className="custom-item-date">
          <DateRange start={item.start} end={item.end} noCurrent />
        </div>
        <div className="custom-item-location">{item.location}</div>
      </div>
    )}
    {!isEmptyContent(item.content) && (
      <div className="content markdown">
        <ReactMarkdown source={item.content} />
      </div>
    )}
  </div>
)

const removeExcessCustomItem = (item: CVTypes.CustomItem) => {
  if (item.content) {
    const lines = item.content.trim().split('\n')
    const updatedContent = removeExcessArray(lines).join('\n')
    return { ...item, content: updatedContent }
  }

  return item
}
const canRemoveExcessCustomItem = (item: CVTypes.CustomItem) => {
  if (item.content) {
    return item.content.length > 0
  }

  return false
}
const getRemainingCustomItem = (full: CVTypes.CustomItem, rendered: CVTypes.CustomItem) => {
  if (JSON.stringify(full) === JSON.stringify(rendered)) {
    return undefined
  }

  if (full.content && rendered.content) {
    const updatedContent = full.content.replace(rendered.content, '')

    return { ...full, content: updatedContent }
  }

  return full
}

export const FlexibleCustomItem = flexibleSection<CVTypes.CustomItem>(
  (ci) => <CustomItemRow item={ci} />,
  removeExcessCustomItem,
  canRemoveExcessCustomItem,
  getRemainingCustomItem,
  'custom-item',
)
