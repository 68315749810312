import React from 'react'
import { TextInput, DateInput, renderForm } from './GenericForm'
import { DateItem } from '../render/dateItem'
import * as CVTypes from '../types'
import ItemList from './ItemList'

const PublicationEntry: React.FC<{ entry: CVTypes.Publication }> = ({ entry }) => (
  <div>
    <div>{entry.url ? <a href={entry.url}>{entry.title}</a> : <span className="publication-title">{entry.title}</span>}</div>
    <div className="publication-date">
      {entry.date && (
        <span className="text-muted">
          <DateItem cvDate={entry.date} />
        </span>
      )}
    </div>
  </div>
)

const PublicationForm: React.FC<{ entry: CVTypes.Publication; onChange: (_?: CVTypes.Publication) => void }> = ({ entry, onChange }) => {
  return renderForm({
    fields: [
      [{ label: 'Title', name: 'title', element: <TextInput value={entry.title} name={'title'} onChange={(title) => onChange({ ...entry, title })} /> }],
      [
        { label: 'Url', name: 'url', element: <TextInput value={entry.url} name={'url'} onChange={(url) => onChange({ ...entry, url })} />, span: 75 as 75 },
        { label: 'Publication date', name: 'date', element: <DateInput value={entry.date} name={'date'} onChange={(date) => onChange({ ...entry, date })} /> },
      ],
    ],
  })
}

export const PublicationList: React.FC<{ items?: CVTypes.Publication[]; onChange: (_?: CVTypes.Publication[]) => void }> = ({ items, onChange }) => (
  <ItemList
    formTitle="Publications"
    addTitle="Add publication"
    items={items}
    empty={{ title: '' }}
    renderItem={(entry) => <PublicationEntry entry={entry} />}
    renderForm={(entry, onChange) => <PublicationForm entry={entry} onChange={onChange} />}
    onChange={onChange}
  />
)
