import React, { useState } from 'react'

import * as CVTypes from '../types'
import { PersonalDetailsForm } from './PersonalDetailsForm'
import { ProfileForm } from './ProfileForm'
import { EditorSection } from './EditorSection'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import { reorder } from './reorder'
import { v4 as uuid } from 'uuid'
import { SaveLoad } from './SaveLoad'

export const CvForm: React.FC<{
  cvBundle: CVTypes.CVBundle
  onChange: (_: CVTypes.CVBundle) => void
}> = ({ cvBundle, onChange }) => {
  const updatedCv = cvBundle.cv
  const updatedSettings = cvBundle.settings

  const setUpdatedCv = (c: CVTypes.CV) => {
    const filteredSections = updatedSettings.sectionOrder.filter(({ id, type }) => {
      if (type !== 'custom-section') {
        return true
      }

      return c.customSections && c.customSections.find((cs) => cs.id === id) !== undefined
    })

    onChange({
      cv: c,
      settings: { ...cvBundle.settings, sectionOrder: filteredSections },
    })
  }

  const [sectionAddButton, setSectionAddButton] = useState<HTMLElement | null>(null)

  const setUpdatedSettings = (settings: CVTypes.TemplateSettings) => {
    onChange({
      cv: cvBundle.cv,
      settings,
    })
  }

  function onDragEnd(result: DropResult) {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    setUpdatedSettings({ ...updatedSettings, sectionOrder: reorder(updatedSettings.sectionOrder, result.source.index, result.destination.index) })
  }

  return (
    <>
      <div className="row">
        <div className="col">
          <SaveLoad
            cvBundle={cvBundle}
            onLoad={(bundle) => {
              onChange(bundle)
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="p-3">
            <PersonalDetailsForm
              personalDetails={updatedCv.personalDetails ? updatedCv.personalDetails : {}}
              onChange={(personalDetails) => {
                setUpdatedCv({ ...updatedCv, personalDetails })
              }}
            />
          </div>
          <div className="p-3">
            <ProfileForm
              profile={updatedCv.profile}
              onChange={(profile) => {
                setUpdatedCv({ ...updatedCv, profile })
              }}
            />
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="section-list">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps} className="section-list">
                  {updatedSettings.sectionOrder.map((sectionOrderItem, i) => (
                    <Draggable key={i} draggableId={`${i}`} index={i}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} className={snapshot.isDragging ? 'dragging' : ''}>
                          <div className="p-3 editor-section">
                            <div className={`section-drag-handle`} {...provided.dragHandleProps}>
                              <span>::</span>
                            </div>
                            <EditorSection cv={updatedCv} setUpdatedCv={setUpdatedCv} sectionId={sectionOrderItem.id} />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className="p-3">
            <button
              ref={setSectionAddButton}
              type="button"
              className="btn btn-link"
              onClick={() => {
                const newSection = {
                  id: uuid(),
                  items: [],
                }

                onChange({
                  ...cvBundle,
                  cv: { ...cvBundle.cv, customSections: updatedCv.customSections ? updatedCv.customSections.concat([newSection]) : [newSection] },
                  settings: {
                    ...cvBundle.settings,
                    sectionOrder: cvBundle.settings.sectionOrder.concat([
                      {
                        type: 'custom-section',
                        id: newSection.id,
                      },
                    ]),
                  },
                })

                if (sectionAddButton) {
                  sectionAddButton.blur()
                }
              }}
            >
              Add custom section
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
