import React, { useEffect, useRef } from 'react'
import * as CVTypes from '../types'
import { MdEmail, MdPhone, MdLanguage, MdPlace } from 'react-icons/md'
import { SectionTitle } from './SectionTitle'

export const ProfilePicture: React.FC<{
  profilePicture?: string
  onRenderFinished: (height: number) => void
}> = ({ profilePicture, onRenderFinished }) => {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    requestAnimationFrame(() => {
      const height = ref.current ? Math.round(ref.current.getBoundingClientRect().height) : 0
      onRenderFinished(height)
    })
  }, [])

  return (
    <div ref={ref} className="profile-picture">
      <img src={profilePicture} />
    </div>
  )
}

export const ContactDetails: React.FC<{
  contact?: CVTypes.ContactInfo
  onRenderFinished: (height: number) => void
}> = ({ contact, onRenderFinished }) => {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    requestAnimationFrame(() => {
      const height = ref.current ? Math.round(ref.current.getBoundingClientRect().height) : 0
      onRenderFinished(height)
    })
  }, [])

  return (
    <div ref={ref} className="contact-details">
      {contact && JSON.stringify({ ...contact, fullName: undefined }) !== JSON.stringify({}) && <SectionTitle title="Contact" />}
      <div className="contact-entry">
        <span>{contact?.email}</span>
        {contact?.email && (
          <span className="icon">
            <MdEmail />
          </span>
        )}
      </div>
      <div className="contact-entry">
        <span>{contact?.phone}</span>
        {contact?.phone && (
          <span className="icon">
            <MdPhone />
          </span>
        )}
      </div>
      <div className="contact-entry">
        <span>{contact?.location}</span>
        {contact?.location && (
          <span className="icon">
            <MdPlace />
          </span>
        )}
      </div>
      <div className="contact-entry">
        <span>{contact?.url}</span>
        {contact?.url && (
          <span className="icon">
            <MdLanguage />
          </span>
        )}
      </div>
    </div>
  )
}

export const Name: React.FC<{
  name?: string
  onRenderFinished: (height: number) => void
}> = ({ name, onRenderFinished }) => {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    requestAnimationFrame(() => {
      const height = ref.current ? Math.round(ref.current.getBoundingClientRect().height) : 0
      onRenderFinished(height)
    })
  }, [])

  return (
    <div ref={ref} className="name">
      {name}
    </div>
  )
}

export const Tagline: React.FC<{
  tagline?: string
  onRenderFinished: (height: number) => void
}> = ({ tagline, onRenderFinished }) => {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    requestAnimationFrame(() => {
      const height = ref.current ? Math.round(ref.current.getBoundingClientRect().height) : 0
      onRenderFinished(height)
    })
  }, [])

  return (
    <div ref={ref} className="tagline">
      {tagline}
    </div>
  )
}

export const ProfileText: React.FC<{
  profile?: string
  onRenderFinished: (height: number) => void
}> = ({ profile, onRenderFinished }) => {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    requestAnimationFrame(() => {
      const height = ref.current ? Math.round(ref.current.getBoundingClientRect().height) : 0
      onRenderFinished(height)
    })
  }, [])

  return (
    <div ref={ref} className="profile">
      {profile && <SectionTitle title="Profile" />}
      {profile}
    </div>
  )
}
