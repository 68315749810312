import React, { useState, useEffect, useMemo } from 'react'

import { Document, Page } from 'react-pdf'
import * as CVTypes from '../types'
import { getPdf } from '../Api'
import { useResizeObserver } from '../useResizeObserver'
import { downloadBlob } from './downloadBlob'
import { useDebounce } from 'use-debounce'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'

export const PdfViewer: React.FC<{
  cvBundle: CVTypes.CVBundle
  onTemplatePickRequest: () => void
}> = ({ cvBundle, onTemplatePickRequest }) => {
  const ratio = 1.4159
  const [ref, { height, width }] = useResizeObserver()
  const [numPages, setNumPages] = useState<number | null>(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [refreshing, setRefreshing] = useState(false)

  const [debouncedBundle] = useDebounce(cvBundle, 1000, {
    equalityFn: (left, right) => JSON.stringify(left) === JSON.stringify(right),
    leading: true,
    maxWait: 5000,
  })

  const [pdfBuffer, setPdfBuffer] = useState<ArrayBuffer | undefined>()

  const file = useMemo(
    () => ({
      data: pdfBuffer,
    }),
    [pdfBuffer],
  )

  const onPdfLoad = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
  }

  //  const debouncedBundle = { cv: debouncedCv, settings: cvBundle.settings }

  useEffect(() => {
    setRefreshing(true)
    getPdf(debouncedBundle).then(
      (buffer) => {
        setPdfBuffer(buffer)
        setRefreshing(false)
      },
      (error) => {
        console.log(error)
        setRefreshing(false)
      },
    )
  }, [JSON.stringify({ ...debouncedBundle, cv: { ...debouncedBundle.cv, title: '' } })])

  const adjustedHeight = (function () {
    if (!height || !width) {
      return 400
    }

    if (height / ratio <= width) {
      return height
    }

    return width * ratio
  })()

  const initialLoadingDiv = (
    <div
      className="initial-loading"
      style={{
        width: `${adjustedHeight / ratio}px`,
        height: `${adjustedHeight}px`,
      }}
    ></div>
  )

  const refreshingDiv = (
    <div className="refreshing">
      <div className="refreshing-indicator"></div>
    </div>
  )

  return (
    <div className="pdf-preview">
      <div ref={ref} className="pdf-doc-container">
        <div className="pdf-doc-with-controls">
          <div className="pdf-controls-top">
            <button className="btn btn-secondary" onClick={onTemplatePickRequest}>
              Pick template
            </button>
            <button
              className="btn btn-primary"
              disabled={file.data === undefined}
              onClick={() => {
                if (file.data) {
                  downloadBlob(file.data, cvBundle.cv.title ? cvBundle.cv.title : 'Untitled')
                }
              }}
            >
              Download PDF
            </button>
          </div>
          <div className="pdf-doc">
            {pdfBuffer && (
              <Document file={file} loading={initialLoadingDiv} onLoadSuccess={onPdfLoad}>
                <Page pageNumber={pageNumber} renderTextLayer={false} height={adjustedHeight} />
              </Document>
            )}
            {!pdfBuffer && initialLoadingDiv}
            {refreshing && refreshingDiv}
          </div>
          {numPages && (
            <div className="pdf-controls-bottom">
              <div className="page-turner">
                <MdChevronLeft
                  className="page-back"
                  onClick={() => {
                    if (pageNumber > 1) {
                      setPageNumber(pageNumber - 1)
                    }
                  }}
                />
                <div className="page-numbers">
                  {pageNumber} of {numPages}
                </div>
                <MdChevronRight
                  className="page-forward"
                  onClick={() => {
                    if (numPages && pageNumber < numPages) {
                      setPageNumber(pageNumber + 1)
                    }
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
