import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'

export const flexibleSection: <T>(
  renderItems: (_: T, renderCount: number) => React.ReactElement | boolean,
  removeExcess: (_: T) => T,
  canRemove: (_: T) => boolean,
  getRemaining: (full: T, rendered: T) => T | undefined,
  id: string,
) => React.FC<{
  availableHeight: number
  renderCount: number
  items: T
  onRenderFinished: (remaining: T | undefined, height: number) => void
}> = (renderItems, removeExcess, canRemove, getRemaining, id) => ({ availableHeight, renderCount, items, onRenderFinished }) => {
  const [renderedItems, setRenderedItems] = useState(items)
  const [randomId] = useState(Math.round(Math.random() * 100))
  const [ready, setReady] = useState<boolean>(false)
  const [height, setHeight] = useState<number>(0)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setRenderedItems(items)
  }, [JSON.stringify(items)])

  useLayoutEffect(() => {
    requestAnimationFrame(() => {
      if (ready) {
        return
      }

      const height = ref.current ? Math.round(ref.current.getBoundingClientRect().height) : 0
      setHeight(height)
      // console.log(`bounding box height, id ${randomId}: ${height}`)
      console.log(
        `bounding box height, id: ${randomId}: h: ${height}, av ${availableHeight}, rendered: ${renderedItems && ((renderedItems as unknown) as string[]).length}, items: ${
          items && ((items as unknown) as string[]).length
        }`,
      )

      if (height > availableHeight && availableHeight > 0 && renderedItems !== undefined && canRemove(renderedItems)) {
        setRenderedItems(removeExcess(renderedItems))
      } else if (height <= availableHeight && availableHeight > 0 && Math.round(height) > 0) {
        console.log(`id: ${randomId} is ready`)
        setReady(true)
      } else if (renderedItems && !canRemove(renderedItems)) {
        console.log(`id: ${randomId} is ready - can't remove more`)
        setReady(true)
      } else if (!items) {
        console.log(`id: ${randomId} is ready - no items to render`)
        setReady(true)
      }
    })
  }, [JSON.stringify(renderedItems), Math.round(availableHeight)])
  const remaining = !ready ? items : items && renderedItems && getRemaining(items, renderedItems)

  useEffect(() => {
    if (ready) {
      console.log(`${id} rendered, height ${height}`)
      onRenderFinished(remaining, height)
    }
  }, [ready])

  return (
    <div
      className={`${id}-wrapper`}
      style={{
        overflowY: 'auto',
      }}
      ref={ref}
    >
      {renderedItems && renderItems(renderedItems, renderCount)}
    </div>
  )
}

export const removeExcessArray = <T,>(items: T[]) => items.slice(0, items.length - 1)
export const getRemainingArray = <T,>(full: T[], rendered: T[]) => {
  const remaining = full.slice(rendered.length, full.length)
  return remaining.length ? remaining : undefined
}
export const canRemoveExcessArray = <T,>(items: T[]) => items.length > 0
