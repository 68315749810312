import React, { useState, useRef, useLayoutEffect, useCallback } from 'react'

interface ObservedDimensions {
  height?: number
  width?: number
}

export const useResizeObserver: () => [(node: HTMLElement | null) => void, ObservedDimensions, HTMLElement | null] = () => {
  const [entry, setEntry] = useState<ObservedDimensions>({})
  const [node, setNode] = useState<HTMLElement | null>(null)
  const observer = useRef<ResizeObserver>(null) as React.MutableRefObject<ResizeObserver>

  const disconnect = useCallback(() => {
    const { current } = observer
    current && current.disconnect()
  }, [])

  const observe = useCallback(() => {
    observer.current = new ResizeObserver(([entry]) => setEntry(entry ? entry.contentRect : {}))
    node &&
      observer.current.observe(node, {
        box: 'border-box',
      })
  }, [node])

  useLayoutEffect(() => {
    observe()
    return () => disconnect()
  }, [disconnect, observe])

  return [setNode, entry, node]
}
