import React from 'react'
import * as CVTypes from '../types'
import { DateItem } from '../render/dateItem'

export const DateRange: React.FC<{ start?: CVTypes.CVDate; end?: CVTypes.CVDate; noCurrent?: boolean }> = ({ start, end, noCurrent }) => (
  <div>
    {start && (
      <span className="text-muted">
        <DateItem cvDate={start} />
      </span>
    )}
    {start && end && <span className="text-muted"> - </span>}
    {end ? (
      <span className="text-muted">
        <DateItem cvDate={end} />
      </span>
    ) : (
      <>{start && !noCurrent && <span className="text-muted"> - Current</span>}</>
    )}
  </div>
)

export default DateRange
