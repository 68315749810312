import React from 'react'
import './privacy-policy.scss'

export const PrivacyPolicy = () => (
  <div className="bootstrap-theme privacy-policy" style={{ padding: '2rem' }}>
    <h1>AvoCV Privacy Policy</h1>
    <p>This privacy policy will explain how our organization uses the personal data we collect from you when you use our website.</p>
    <p>Topics:</p>
    <ul>
      <li>What data do we collect?</li>
      <li>How do we collect your data?</li>
      <li>How will we use your data?</li>
      <li>How do we store your data?</li>
      <li>What are your data protection rights?</li>
      <li>Changes to our privacy policy</li>
      <li>How to contact us</li>
    </ul>
    <h3>What data do we collect?</h3>
    <p>AvoCV collects the following data:</p>
    <ul>
      <li>Personal identification information (Name, email address, phone number, etc.)</li>
      <li>Your work experience, education, skillset and other information you choose to fill out when creating your resume</li>
    </ul>
    <h3>How do we collect your data?</h3>
    <p>You directly provide AvoCV with most of the data we collect. We collect data and process data when you:</p>
    <ul>
      <li>Fill out resume/CV form to generate a PDF</li>
    </ul>
    <h3>How do we store your data?</h3>
    <p>AvoCV securely stores your data at AWS. Data is encrypted during transfer and storage.</p>
    <p>Your data is stored for the purposes of generating a resume in PDF format.</p>
    <p>
      AvoCV will keep your resume data for 6 hours to improve site{"'"}s performance. This allows us to display your generated resume faster if PDF was already generated before for
      the same data.
      <br />
      Once this time period has expired, we will delete your data by automated process.
    </p>

    <h3>What are your data protection rights?</h3>
    <p>AvoCv would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
    <p>
      <strong>The right to access</strong> – You have the right to request AvoCV for copies of your personal data. We may charge you a small fee for this service.
    </p>
    <p>
      <strong>The right to rectification</strong> – You have the right to request that AvoCV correct any information you believe is inaccurate. You also have the right to request
      AvoCV to complete the information you believe is incomplete.
    </p>
    <p>
      <strong>The right to erasure</strong> – You have the right to request that AvoCV erase your personal data, under certain conditions.
    </p>
    <p>
      <strong>The right to restrict processing</strong> – You have the right to request that AvoCV restrict the processing of your personal data, under certain conditions.
    </p>
    <p>
      <strong>The right to object to processing</strong> – You have the right to object to AvoCV’s processing of your personal data, under certain conditions.
    </p>
    <p>
      <strong>The right to data portability</strong> – You have the right to request that AvoCV transfer the data that we have collected to another organization, or directly to
      you, under certain conditions.
    </p>
    <p>
      If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email: <strong>info@avocv.com</strong>
    </p>

    <h3>Changes to our privacy policy</h3>
    <p>AvoCV keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on 14 June 2020.</p>
    <h3>How to contact us</h3>
    <p>
      Email us at: <strong>info@avocv.com</strong>
    </p>
  </div>
)
