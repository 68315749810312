import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import * as CVTypes from './types'
import Template from './Template'
import { cv, settings } from './testData'

const useQuery = () => new URLSearchParams(useLocation().search)

export const TemplatePreview: React.FC<{}> = ({}) => {
  const query = useQuery()
  const templateId = query.get('template-id')

  const templateSettings = templateId ? { ...settings, templateId } : settings

  return <Template cv={cv} settings={templateSettings} />
}
