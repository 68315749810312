import React, { useState, useEffect } from 'react'

import * as CVTypes from '../types'
import { PdfViewer } from './PdfViewer'
import { CvForm } from './CvForm'
import './editor.scss'
import { defaultSettings } from '../testData'
import { TemplatePicker } from './TemplatePicker'
import { useDebounce } from 'use-debounce'

const getSavedCv = () => {
  const savedCv = localStorage.getItem('savedCv')

  return savedCv !== null ? JSON.parse(savedCv) : { cv: {}, settings: defaultSettings }
}

const Editor: React.FC<{}> = () => {
  const [cvBundle, setCvBundle] = useState<CVTypes.CVBundle>(getSavedCv())
  const [pickingTemplate, setPickingTemplate] = useState(false)

  const updatedSettings = cvBundle.settings

  const setUpdatedSettings = (settings: CVTypes.TemplateSettings) => {
    setCvBundle({
      cv: cvBundle.cv,
      settings,
    })
  }

  const [debouncedBundle] = useDebounce(cvBundle, 3000, {
    equalityFn: (left, right) => JSON.stringify(left) === JSON.stringify(right),
    maxWait: 5000,
  })

  useEffect(() => {
    localStorage.setItem('savedCv', JSON.stringify(debouncedBundle))
  }, [JSON.stringify(debouncedBundle)])

  return (
    <div className="bootstrap-theme editor">
      <div className="container-fluid">
        {!pickingTemplate && (
          <div className="row">
            <div className="col-12 col-lg-6 px-lg-5 py-lg-3">
              <CvForm
                cvBundle={cvBundle}
                onChange={(b) => {
                  setCvBundle(b)
                }}
              />
            </div>
          </div>
        )}
        {pickingTemplate && (
          <div className="row">
            <div className="col-12 col-lg-6 pb-3 px-lg-5">
              <TemplatePicker
                selected={cvBundle.settings.templateId}
                onPick={(templateId) => setUpdatedSettings({ ...updatedSettings, templateId })}
                onCancel={() => setPickingTemplate(false)}
              />
            </div>
          </div>
        )}
      </div>

      <PdfViewer cvBundle={cvBundle} onTemplatePickRequest={() => setPickingTemplate(true)} />
    </div>
  )
}

export default Editor
