import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Editor from './editor/Editor'
import { TemplateRenderer } from './TemplateRenderer'
import { TemplatePreview } from './TemplatePreview'
import { LandingPage } from './LandingPage'
import { PrivacyPolicy } from './PrivacyPolicy'
import { TermsAndConditions } from './TermsAndConditions'
import 'typeface-roboto'

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/template-preview">
          <TemplatePreview />
        </Route>
        <Route path="/template">
          <TemplateRenderer />
        </Route>
        <Route path="/editor">
          <Editor />
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route path="/terms-and-conditions">
          <TermsAndConditions />
        </Route>
        <Route path="/">
          <LandingPage />
        </Route>
      </Switch>
    </Router>
  )
}

export default App
