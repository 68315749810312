import React from 'react'

export const SectionTitle: React.FC<{
  title?: string
}> = ({ title }) => (
  <div className="section-title">
    <h1>{title}</h1>
    <div className="line"></div>
    <div className="line-1"></div>
  </div>
)
