import React, { useState, useEffect } from 'react'
import * as CVTypes from '../types'
import RichTextEditor, { EditorValue } from 'react-rte'
import { DraggableArea } from 'react-draggable-tags'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { MdClose } from 'react-icons/md'
import dayjs from 'dayjs'

type FieldSpan = 25 | 50 | 75

const spanToClass = (span: FieldSpan): string => {
  switch (span) {
    case 25:
      return 'col-6 col-lg-3'
    case 50:
      return 'col-12 col-lg-6'
    case 75:
      return 'col-12 col-lg-9'
  }
}

interface FieldConfig {
  element: React.ReactElement
  name: string
  label?: string
  span?: FieldSpan
}

interface FormConfig {
  title?: string
  fields: FieldConfig[][]
}

export const renderForm = (config: FormConfig) => (
  <>
    {config.title && <h5>{config.title}</h5>}
    <form>
      {config.fields.map((row, i) => (
        <div key={i} className="row">
          {row.map((field, iCol) => {
            const colClass = field?.span ? spanToClass(field?.span) : 'col'

            const element = field.label ? (
              <>
                <label htmlFor={field.name}>{field.label}</label>
                {field.element}
              </>
            ) : (
              field.element
            )

            return (
              <div key={iCol} className={`form-group ${colClass}`}>
                {element}
              </div>
            )
          })}
        </div>
      ))}
    </form>
  </>
)

export const TextInput: React.FC<{ name: string; value?: string; invalid?: boolean; autoFocus?: boolean; onBlur?: () => void; onChange: (_: string) => void }> = ({
  name,
  value,
  invalid,
  autoFocus,
  onBlur,
  onChange,
}) => (
  <input
    type="text"
    className={`form-control ${invalid ? 'is-invalid' : ''}`}
    autoFocus={autoFocus}
    id={name}
    value={value ? value : ''}
    onChange={(e) => onChange(e.target.value)}
    onBlur={onBlur}
  />
)

export const EmailInput: React.FC<{ name: string; value?: string; onChange: (_: string) => void }> = ({ name, value, onChange }) => (
  <input type="email" className="form-control" id={name} value={value ? value : ''} onChange={(e) => onChange(e.target.value)} />
)

export const TextArea: React.FC<{ name: string; value?: string; onChange: (_: string) => void }> = ({ name, value, onChange }) => (
  <textarea className="form-control" id={name} rows={3} value={value ? value : ''} onChange={(e) => onChange(e.target.value)} />
)

export const DateInput: React.FC<{ name: string; value?: CVTypes.CVDate; onChange: (_?: CVTypes.CVDate) => void }> = ({ value, onChange }) => (
  <DatePicker
    onChange={(date) => {
      console.log(value)

      const updated = date
        ? {
            date: dayjs(date).format('YYYY-MM-DD'),
            resolution: value?.resolution,
          }
        : undefined

      onChange(updated)
    }}
    selected={value ? new Date(value.date) : undefined}
    showYearDropdown
    showMonthDropdown
    showPopperArrow={false}
    className="form-control"
  />
)

export const TagListInput: React.FC<{ name: string; value?: string[]; onChange: (_?: string[]) => void }> = ({ value, onChange }) => {
  const [newTag, setNewTag] = useState<string>('')

  return (
    <div className="tag-input">
      <div className="tags-area">
        <DraggableArea
          tags={value ? value.map((v) => ({ id: v, content: v })) : []}
          render={({ tag }) => (
            <span className="badge badge-primary">
              {tag.content}
              <span
                className="tag-close"
                onClick={() => {
                  if (value) {
                    onChange(value.filter((t) => t !== tag.id))
                  }
                }}
              >
                <MdClose />
              </span>
            </span>
          )}
          onChange={(tags) => {
            onChange(tags.map((t) => t.content))
          }}
        />
      </div>
      <div className="tag-add-area">
        <input
          type="text"
          className="form-control"
          value={newTag}
          onChange={(e) => {
            setNewTag(e.target.value)
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && newTag) {
              onChange(value ? value.concat([newTag]) : [newTag])
              setNewTag('')
            }
          }}
        />
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            if (newTag) {
              onChange(value ? value.concat([newTag]) : [newTag])
              setNewTag('')
            }
          }}
        >
          Add
        </button>
      </div>
    </div>
  )
}

export const RichInput: React.FC<{ name: string; value?: string; onChange: (_: string) => void }> = ({ value, onChange }) => {
  const [richVal, setRichVal] = useState<EditorValue>(value ? RichTextEditor.createValueFromString(value, 'markdown') : RichTextEditor.createEmptyValue())

  useEffect(() => {
    if (richVal.toString('markdown') !== value) {
      setRichVal(value ? RichTextEditor.createValueFromString(value, 'markdown') : RichTextEditor.createEmptyValue())
    }
  }, [value])

  return (
    <RichTextEditor
      value={richVal}
      onChange={(v) => {
        setRichVal(v)
        const markdown = v.toString('markdown')
        onChange(markdown)
      }}
      toolbarConfig={{
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN'],
        INLINE_STYLE_BUTTONS: [
          { label: 'Bold', style: 'BOLD' },
          { label: 'Italic', style: 'ITALIC' },
          { label: 'Underline', style: 'UNDERLINE' },
        ],
        BLOCK_TYPE_DROPDOWN: [
          { label: 'Normal', style: 'unstyled' },
          { label: 'Heading Large', style: 'header-one' },
          { label: 'Heading Medium', style: 'header-two' },
          { label: 'Heading Small', style: 'header-three' },
        ],
        BLOCK_TYPE_BUTTONS: [
          { label: 'Unordered List', style: 'unordered-list-item' },
          { label: 'Ordered List', style: 'ordered-list-item' },
        ],
      }}
      editorClassName="rich-editor"
      toolbarClassName="rich-editor-toolbar"
    />
  )
}
