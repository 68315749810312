import React from 'react'
import { TextInput, DateInput, RichInput, renderForm } from './GenericForm'
import DateRange from './DateRange'
import * as CVTypes from '../types'
import ItemList from './ItemList'

const EducationEntry: React.FC<{ entry: CVTypes.Education }> = ({ entry }) => (
  <div>
    <div>
      {entry.school}
      {entry.degree && entry.school ? ', ' : ''}
      {entry.degree}
    </div>
    <DateRange start={entry.start} end={entry.end} />
    <div>
      <span className="text-muted">{entry.location}</span>
    </div>
  </div>
)

const EducationForm: React.FC<{ entry: CVTypes.Education; onChange: (_?: CVTypes.Education) => void }> = ({ entry, onChange }) => {
  return renderForm({
    fields: [
      [
        {
          label: 'School',
          name: 'school',
          element: <TextInput value={entry.school} name={'school'} onChange={(school) => onChange({ ...entry, school })} />,
        },
      ],
      [
        {
          label: 'Degree',
          name: 'degree',
          element: <TextInput value={entry.degree} name={'degree'} onChange={(degree) => onChange({ ...entry, degree })} />,
        },
      ],
      [
        {
          label: 'Location',
          name: 'location',
          element: <TextInput value={entry.location} name={'location'} onChange={(location) => onChange({ ...entry, location })} />,
          span: 50 as 50,
        },
        {
          label: 'From',
          name: 'start',
          element: <DateInput value={entry.start} name={'start'} onChange={(start) => onChange({ ...entry, start })} />,
        },
        {
          label: 'To',
          name: 'end',
          element: <DateInput value={entry.end} name={'end'} onChange={(end) => onChange({ ...entry, end })} />,
        },
      ],
      [
        {
          label: 'Description',
          name: 'description',
          element: <RichInput value={entry.description} name={'description'} onChange={(description) => onChange({ ...entry, description })} />,
        },
      ],
    ],
  })
}

export const EducationList: React.FC<{ items?: CVTypes.Education[]; onChange: (_?: CVTypes.Education[]) => void }> = ({ items, onChange }) => (
  <ItemList
    formTitle="Education"
    addTitle="Add education"
    items={items}
    empty={{}}
    renderItem={(entry) => <EducationEntry entry={entry} />}
    renderForm={(entry, onChange) => <EducationForm entry={entry} onChange={onChange} />}
    onChange={onChange}
  />
)
