import React, { useState } from 'react'

import * as CVTypes from '../types'
import { downloadBlob } from './downloadBlob'
import { TextInput } from './GenericForm'
import { defaultSettings } from '../testData'

function str2ab(str: string) {
  return new TextEncoder().encode(str)
}

export const SaveLoad: React.FC<{
  cvBundle: CVTypes.CVBundle
  onLoad: (_: CVTypes.CVBundle) => void
}> = ({ cvBundle, onLoad }) => {
  const [uploadInput, setUploadInput] = useState<HTMLInputElement | null>(null)
  const [editing, setEditing] = useState(false)
  const [clearing, setClearing] = useState(false)

  const onFileUpload: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    if (event?.target?.files && event.target.files[0]) {
      const file = event.target.files[0]
      const loader = new FileReader()
      loader.onload = function (loadEvent) {
        if (loadEvent?.target?.readyState !== 2) {
          return
        }
        if (loadEvent.target.error) {
          alert('Error while reading file ' + file.name + ': ' + loadEvent.target.error)
          return
        }
        const result = loadEvent.target.result
        if (result) {
          const cvBundle = JSON.parse(result as string) as CVTypes.CVBundle
          onLoad(cvBundle)
        }
      }
      loader.readAsText(file, 'utf-8')
    }
  }

  const onUploadInitiate = () => {
    if (uploadInput !== null) {
      uploadInput.click()
    }
  }

  const clearCv = () => {
    onLoad({ cv: {}, settings: defaultSettings })
  }

  const cvTitle = editing ? (
    <div className="cv-title">
      <TextInput
        autoFocus={true}
        value={cvBundle.cv.title}
        name={'title'}
        onBlur={() => {
          setEditing(false)
        }}
        onChange={(title) => onLoad({ ...cvBundle, cv: { ...cvBundle.cv, title: title } })}
      />
    </div>
  ) : (
    <div
      className={`cv-title ${!cvBundle.cv.title ? 'untitled' : ''}`}
      onClick={() => {
        setEditing(true)
      }}
    >
      <h4>{cvBundle.cv.title ? cvBundle.cv.title : <span className="text-muted">Untitled</span>}</h4>
    </div>
  )

  return (
    <div className="save-load">
      <div className="editor-controls-header">
        {cvTitle}
        <button
          className="btn btn-primary"
          onClick={() => {
            setClearing(true)
          }}
        >
          New resume
        </button>
        <div className="save-load-upload" title="Upload a previously downloaded AvoCV file">
          <input type="file" ref={setUploadInput} onChange={onFileUpload} style={{ display: 'none' }} />
          <button className="btn btn-secondary upload-button" onClick={onUploadInitiate}>
            Upload
          </button>
        </div>
        <button
          title="Download this resume as a file"
          className="btn btn-secondary"
          onClick={() => {
            downloadBlob(str2ab(JSON.stringify(cvBundle)), cvBundle.cv.title ? cvBundle.cv.title : 'Untitled', 'json')
          }}
        >
          Download
        </button>
      </div>
      {clearing && (
        <div className="clearing-confirmation">
          <span>Starting a new resume will clear the resume you are currently working on. Make sure you have downloaded the latest copy.</span>
          <div className="clearing-buttons">
            <button
              type="button"
              className="btn btn-warning"
              onClick={() => {
                setClearing(false)
                clearCv()
              }}
            >
              Confirm
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setClearing(false)
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
