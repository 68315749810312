import React from 'react'
import * as CVTypes from '../types'
import { flexibleSection, removeExcessArray, canRemoveExcessArray, getRemainingArray } from './flexibleSection'
import { DateRange } from '../editor/DateRange'
import ReactMarkdown from 'react-markdown'
import { SectionTitle } from './SectionTitle'
import isEmptyContent from './isEmptyContent'

const EducationRow: React.FC<{ education: CVTypes.Education }> = ({ education }) => (
  <div className="education-entry">
    <div className="education-header">
      <div className="education-school">{education.school}</div>
      <div className="education-degree">{education.degree}</div>
    </div>
    <div className="education-date">
      <DateRange start={education.start} end={education.end} />
    </div>
    {!isEmptyContent(education.description) && (
      <div className="description markdown">
        <ReactMarkdown source={education.description} />
      </div>
    )}
  </div>
)

const RawEducationSection: React.FC<{ education: CVTypes.Education[]; renderCount: number }> = ({ education, renderCount }) => (
  <div className="education">
    {renderCount === 0 && <SectionTitle title="Education" />}
    {education.map((e, i) => (
      <EducationRow key={i} education={e} />
    ))}
  </div>
)

export const EducationSection = flexibleSection<CVTypes.Education[]>(
  (e, rc) => e.length > 0 && <RawEducationSection renderCount={rc} education={e} />,
  removeExcessArray,
  canRemoveExcessArray,
  getRemainingArray,
  'education',
)
