import React from 'react'
import * as CVTypes from '../types'
import { flexibleSection, removeExcessArray, canRemoveExcessArray, getRemainingArray } from './flexibleSection'
import ReactMarkdown from 'react-markdown'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { SectionTitle } from './SectionTitle'
dayjs.extend(relativeTime)

const SkillBar: React.FC<{ level: number }> = ({ level }) => (
  <div className="skill-bar">
    <div
      className="skill-bar-fill"
      style={{
        width: `${level * 20}%`,
      }}
    ></div>
  </div>
)

const SkillRow: React.FC<{ skill: CVTypes.Skill }> = ({ skill }) => (
  <div className="skill">
    <div className="skill-header">
      <div className="title">{skill.title}</div>

      {!skill.isCurrent && skill.years && <div className="years">{skill.years && `${skill.years} years`}</div>}
      {skill.isCurrent && skill.start && <div className="years">{dayjs(new Date(skill.start.date)).fromNow(true)}</div>}

      <div className="level">{skill.level && <SkillBar level={skill.level} />}</div>
    </div>
    {skill.highlights && (
      <div className="highlights-content markdown">
        <ReactMarkdown source={skill.highlights} />
      </div>
    )}
  </div>
)

const RawSkillsSection: React.FC<{ skills: CVTypes.Skill[]; isHighlights: boolean; renderCount: number }> = ({ skills, isHighlights, renderCount }) => (
  <div className={`skills-section ${isHighlights ? 'skill-highlights' : ''}`}>
    {renderCount === 0 && <SectionTitle title={isHighlights ? 'Skill Highlights' : 'Skills'} />}
    <div className="skill-list">
      {skills.map((s, i) => (
        <SkillRow key={i} skill={s} />
      ))}
    </div>
  </div>
)

export const SkillsSection = flexibleSection<CVTypes.Skill[]>(
  (s, rc) => s.length > 0 && <RawSkillsSection renderCount={rc} isHighlights={false} skills={s} />,
  removeExcessArray,
  canRemoveExcessArray,
  getRemainingArray,
  'skills',
)

export const SkillHighlightsSection = flexibleSection<CVTypes.Skill[]>(
  (s, rc) => s.length > 0 && <RawSkillsSection renderCount={rc} skills={s} isHighlights={true} />,
  removeExcessArray,
  canRemoveExcessArray,
  getRemainingArray,
  'skill-highlights',
)
