import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import * as CVTypes from './types'
import Template from './Template'
import { fetchCvData } from './Api'

const useQuery = () => new URLSearchParams(useLocation().search)

export const TemplateRenderer: React.FC<{}> = ({}) => {
  const query = useQuery()
  const id = query.get('id')

  const [response, setResponse] = useState<{ cv: CVTypes.CV; settings: CVTypes.TemplateSettings } | undefined>()

  useEffect(() => {
    if (id) {
      fetchCvData(id).then(
        (result) => {
          console.log(result)
          setResponse(result)
        },
        (error) => {
          console.log(error)
        },
      )
    }
  }, [])

  return <>{response && <Template cv={response.cv} settings={response.settings} />}</>
}
