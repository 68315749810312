import React, { useEffect, useRef, useReducer, useState } from 'react'
import * as CVTypes from '../types'
import { isReadyToRender, areAllFinished, calcAvailableHeight } from './stateChecks'
import { FlexibleCustomItem } from './customItem'
import { SectionTitle } from './SectionTitle'

interface TempCustomSection {
  id: string
  items: (CVTypes.CustomItem | undefined)[]
}

interface CustomSectionState {
  remaining: TempCustomSection | undefined
  headerHeight: number | undefined
  items: {
    height: number
    hasRemaining: boolean
  }[]
}

type CustomSectionAction =
  | {
      type: 'update-remaining'
      remaining: CVTypes.CustomItem | undefined
      height: number
      position: number
    }
  | {
      type: 'set-header-height'
      height: number
    }

function customSectionReducer(state: CustomSectionState, action: CustomSectionAction): CustomSectionState {
  const updateRemaining = (
    remaining: TempCustomSection | undefined,
    i: number,
    itemNew: CVTypes.CustomItem | undefined,
  ):
    | {
        id: string
        items: (CVTypes.CustomItem | undefined)[]
      }
    | undefined => {
    if (!remaining) {
      return undefined
    }

    const updatedSection = {
      ...remaining,
      items: remaining.items.map((cs1, i1) => {
        if (i1 === i) {
          return itemNew
        }

        return cs1
      }),
    }
    console.log(`Updated section after ${i}`)
    console.log(JSON.parse(JSON.stringify(updatedSection)))

    return updatedSection
  }

  switch (action.type) {
    case 'update-remaining':
      if (!state.items[action.position]) {
        const items = state.items.slice()
        items[action.position] = {
          height: action.height,
          hasRemaining: action.remaining !== undefined,
        }

        return {
          ...state,
          items,
          remaining: updateRemaining(state.remaining, action.position, action.remaining),
        }
      }
      return state

    case 'set-header-height':
      return { ...state, headerHeight: action.height }
  }
}

const prepareRemaining = (toFilter: TempCustomSection | undefined): CVTypes.CustomSection | undefined => {
  if (toFilter) {
    const remainingItems = toFilter.items.filter((ci) => ci !== undefined) as CVTypes.CustomItem[]

    return remainingItems.length > 0
      ? {
          ...toFilter,
          items: remainingItems,
        }
      : undefined
  }

  return undefined
}

export const CustomSection: React.FC<{
  availableHeight: number
  renderCount: number
  section: CVTypes.CustomSection
  onRenderFinished: (remaining: CVTypes.CustomSection | undefined, height: number) => void
}> = ({ availableHeight, renderCount, section, onRenderFinished }) => {
  const headerRef = useRef<HTMLDivElement>(null)

  const [showHeader, setShowHeader] = useState(true)

  const [state, dispatch] = useReducer(customSectionReducer, {
    items: [],
    remaining: section,
    headerHeight: undefined,
  })

  const headerOnly = state.headerHeight && state.headerHeight >= availableHeight

  const finished = headerOnly || areAllFinished(state.items, section.items ? section.items.map((_, i) => i) : [])

  useEffect(() => {
    console.log(`Custom section available height ${availableHeight}`)
    requestAnimationFrame(() => {
      const height = headerRef.current ? Math.round(headerRef.current.getBoundingClientRect().height) : 0
      console.log(`custom section header height: ${height}`)
      dispatch({ type: 'set-header-height', height })
    })
  }, [])

  useEffect(() => {
    if (finished) {
      const itemsHeight = state.items.map((i) => i.height).reduce((a, b) => a + b, 0)
      if (itemsHeight === 0) {
        setShowHeader(false)
      }
      console.log(`Custom section finished! ${itemsHeight}`)
      onRenderFinished(prepareRemaining(state.remaining), itemsHeight + (state.headerHeight !== undefined ? state.headerHeight : 0))
    }
  }, [finished])

  return (
    <div className="custom-section" style={{ overflowY: 'auto' }}>
      <div ref={headerRef} className="custom-section-header" style={{ overflowY: 'auto' }}>
        {renderCount === 0 && showHeader && <SectionTitle title={section.title} />}
      </div>
      {state.headerHeight !== undefined &&
        !headerOnly &&
        section.items.map((s, i) => {
          return (
            isReadyToRender(
              state.items,
              Array.from({ length: i }, (v, k) => k),
            ) && (
              <FlexibleCustomItem
                key={i}
                renderCount={0}
                availableHeight={calcAvailableHeight(availableHeight, state.items, i) - (state.headerHeight !== undefined ? state.headerHeight : 0)}
                items={section.items[i]}
                onRenderFinished={(remaining: CVTypes.CustomItem | undefined, height: number) => {
                  console.log(`Item ${i} finished rendering ${height} ${remaining ? ' `y` ' : ' `n` '}`)
                  dispatch({ type: 'update-remaining', remaining, height, position: i })
                }}
              />
            )
          )
        })}
    </div>
  )
}
