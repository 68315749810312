import * as CVTypes from './types'

interface CvTempateData {
  cv: CVTypes.CV
  settings: CVTypes.TemplateSettings
}

const getBaseUrl = () => {
  if (window.location.hostname === 'localhost') {
    return 'http://localhost:3001'
  }

  return 'https://api.avocv.com'
}

export const fetchCvData = (id: string) => fetch(`${getBaseUrl()}/cv-template-data/${id}`).then((res) => res.json()) as Promise<CvTempateData>

export const getPdf = (templateData: CvTempateData) =>
  fetch(`${getBaseUrl()}/pdf`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
    body: JSON.stringify(templateData),
  }).then((res) => res.arrayBuffer())
