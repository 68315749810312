import React from 'react'
import * as CVTypes from '../types'
import dayjs from 'dayjs'

export const DateItem: React.FC<{ cvDate: CVTypes.CVDate }> = ({ cvDate }) => {
  const date = dayjs(new Date(cvDate.date))

  const format = (() => {
    if (cvDate.resolution === undefined || cvDate.resolution === 'day') return 'MMM D, YYYY'
    if (cvDate.resolution === 'month') return 'MMM, YYYY'
    return 'YYYY'
  })()

  return (
    <>
      <span className="date">{date.format(format)}</span>
    </>
  )
}
