import React from 'react'
import { EmailInput, TextInput, renderForm } from './GenericForm'
import { ImageFileUploader } from './ImageFileUploader'

import * as CVTypes from '../types'

export const PersonalDetailsForm: React.FC<{ personalDetails: CVTypes.PersonalDetails; onChange: (_: CVTypes.PersonalDetails) => void }> = ({ personalDetails, onChange }) => {
  return renderForm({
    title: 'Personal Details',
    fields: [
      [
        {
          label: 'Full Name',
          name: 'fullName',
          element: (
            <TextInput
              value={personalDetails?.contact?.fullName}
              name={'fullName'}
              onChange={(fullName) => onChange({ ...personalDetails, contact: { ...personalDetails.contact, fullName } })}
            />
          ),
          span: 25 as 25,
        },
        {
          name: 'profileImage',
          element: (
            <ImageFileUploader
              imageUri={personalDetails.profileImage}
              onChange={(uri) => onChange({ ...personalDetails, profileImage: uri })}
              onDelete={() => onChange({ ...personalDetails, profileImage: undefined })}
            />
          ),
          span: 25 as 25,
        },
        {
          label: 'Job Title',
          name: 'jobTitle',
          element: <TextInput value={personalDetails?.jobTitle} name={'jobTitle'} onChange={(jobTitle) => onChange({ ...personalDetails, jobTitle })} />,
        },
      ],
      [
        {
          label: 'Email',
          name: 'email',
          element: (
            <EmailInput
              value={personalDetails?.contact?.email}
              name={'email'}
              onChange={(email) => onChange({ ...personalDetails, contact: { ...personalDetails.contact, email } })}
            />
          ),
        },
        {
          label: 'Phone',
          name: 'phone',
          element: (
            <TextInput
              value={personalDetails?.contact?.phone}
              name={'phone'}
              onChange={(phone) => onChange({ ...personalDetails, contact: { ...personalDetails.contact, phone } })}
            />
          ),
        },
      ],
      [
        {
          label: 'Location',
          name: 'location',
          element: (
            <TextInput
              value={personalDetails?.contact?.location}
              name={'location'}
              onChange={(location) => onChange({ ...personalDetails, contact: { ...personalDetails.contact, location } })}
            />
          ),
        },
        {
          label: 'Website',
          name: 'url',
          span: 50 as 50,
          element: (
            <TextInput value={personalDetails?.contact?.url} name={'url'} onChange={(url) => onChange({ ...personalDetails, contact: { ...personalDetails.contact, url } })} />
          ),
        },
      ],
    ],
  })
}
