import React from 'react'
import * as CVTypes from '../types'
import { flexibleSection, removeExcessArray, canRemoveExcessArray, getRemainingArray } from './flexibleSection'
import { DateItem } from './dateItem'
import { SectionTitle } from './SectionTitle'

const PublicationRow: React.FC<{ publication: CVTypes.Publication }> = ({ publication }) => {
  const pub = publication.url ? <a href={publication.url}>{publication.title}</a> : <span>{publication.title}</span>

  return (
    <div className="publication-entry">
      {pub}
      {publication.date && <DateItem cvDate={publication.date} />}
    </div>
  )
}

const RawPublicationsSection: React.FC<{ publications: CVTypes.Publication[]; renderCount: number }> = ({ publications, renderCount }) => (
  <div className="publications">
    {renderCount === 0 && <SectionTitle title="Publications" />}
    {publications.map((p, i) => (
      <PublicationRow key={i} publication={p} />
    ))}
  </div>
)

export const PublicationsSection = flexibleSection<CVTypes.Publication[]>(
  (p, rc) => p.length > 0 && <RawPublicationsSection renderCount={rc} publications={p} />,
  removeExcessArray,
  canRemoveExcessArray,
  getRemainingArray,
  'publications',
)
