import React, { useState } from 'react'
import { TextInput, DateInput, RichInput, renderForm } from './GenericForm'
import DateRange from './DateRange'
import * as CVTypes from '../types'
import ItemList from './ItemList'
import { MdDelete } from 'react-icons/md'

const Entry: React.FC<{ entry: CVTypes.CustomItem }> = ({ entry }) => (
  <div>
    <div>{entry.title ? <span>{entry.title}</span> : <span className="text-muted">Untitled</span>}</div>
    <DateRange start={entry.start} end={entry.end} noCurrent />
    <div>
      <span className="text-muted">{entry.location}</span>
    </div>
  </div>
)

const Form: React.FC<{ entry: CVTypes.CustomItem; onChange: (_?: CVTypes.CustomItem) => void }> = ({ entry, onChange }) => {
  return renderForm({
    fields: [
      [
        {
          label: 'Title',
          name: 'title',
          element: <TextInput value={entry.title} name={'title'} onChange={(title) => onChange({ ...entry, title })} />,
        },
      ],
      [
        {
          label: 'Location',
          name: 'location',
          element: <TextInput value={entry.location} name={'location'} onChange={(location) => onChange({ ...entry, location })} />,
          span: 50 as 50,
        },
        {
          label: 'From',
          name: 'start',
          element: <DateInput value={entry.start} name={'start'} onChange={(start) => onChange({ ...entry, start })} />,
        },
        {
          label: 'To',
          name: 'end',
          element: <DateInput value={entry.end} name={'end'} onChange={(end) => onChange({ ...entry, end })} />,
        },
      ],
      [
        {
          label: 'Content',
          name: 'content',
          element: <RichInput value={entry.content} name={'content'} onChange={(content) => onChange({ ...entry, content })} />,
        },
      ],
    ],
  })
}

const SectionHeader: React.FC<{ title?: string; onChange: (title?: string) => void; canDelete: () => boolean; onDelete: () => void }> = ({
  title,
  onChange,
  canDelete,
  onDelete,
}) => {
  const [editing, setEditing] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const header = editing ? (
    <div>
      <TextInput
        autoFocus={true}
        value={title}
        name={'title'}
        onBlur={() => {
          setEditing(false)
        }}
        onChange={(title) => onChange(title.length > 0 ? title : undefined)}
      />
    </div>
  ) : (
    <>
      <div className="custom-section-header-non-edit">
        <h5 className="title" onClick={() => setEditing(true)}>
          {title ? title : <span className="text-muted">Untitled</span>}
        </h5>
        <div className="list-item-controls">
          <MdDelete
            className={'delete-item'}
            onClick={(e) => {
              if (canDelete()) {
                onDelete()
              } else {
                setDeleting(true)
              }
              e.stopPropagation()
            }}
          />
        </div>
      </div>
      {deleting && (
        <div className="delete-confirmation">
          <span>Are you sure you want to delete this section?</span>
          <div className="delete-buttons">
            <button
              type="button"
              className="btn btn-warning"
              onClick={() => {
                setDeleting(false)
                onDelete()
              }}
            >
              Confirm
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setDeleting(false)
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </>
  )

  return <div className="custom-section-header">{header}</div>
}

export const CustomItemList: React.FC<{
  title?: string
  items?: CVTypes.CustomItem[]
  onChange: (_?: CVTypes.CustomItem[]) => void
  onTitleChange: (_?: string) => void
  onDelete: () => void
}> = ({ title, items, onChange, onTitleChange, onDelete }) => (
  <ItemList
    customHeader={
      <SectionHeader
        title={title}
        onChange={onTitleChange}
        onDelete={onDelete}
        canDelete={() => {
          return !items || items.length === 0
        }}
      />
    }
    addTitle="Add entry"
    items={items}
    empty={{}}
    renderItem={(entry) => <Entry entry={entry} />}
    renderForm={(entry, onChange) => <Form entry={entry} onChange={onChange} />}
    onChange={onChange}
  />
)
