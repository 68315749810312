import React, { useState } from 'react'
import Resizer from 'react-image-file-resizer'
import { MdPortrait, MdDelete } from 'react-icons/md'

export const ImageFileUploader: React.FC<{
  imageUri?: string
  onChange: (_: string) => void
  onDelete: () => void
}> = ({ imageUri, onChange, onDelete }) => {
  const [uploadInput, setUploadInput] = useState<HTMLInputElement | null>(null)

  const onFileUpload: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    if (event?.target?.files && event.target.files[0]) {
      Resizer.imageFileResizer(
        event.target.files[0],
        250,
        250,
        'WEBP',
        100,
        0,
        (uri) => {
          console.log(uri)
          onChange(uri)
        },
        'base64',
      )
    }
  }

  const onUploadInitiate = () => {
    if (uploadInput !== null) {
      uploadInput.click()
    }
  }

  return (
    <div className="image-uploader">
      <div className="upload-button">
        <input type="file" ref={setUploadInput} onChange={onFileUpload} accept="image/*" style={{ display: 'none' }} />
        {imageUri && <img src={imageUri} onClick={onUploadInitiate} />}
        {!imageUri && (
          <div className="placeholder">
            <MdPortrait onClick={onUploadInitiate} />
          </div>
        )}
      </div>
      {imageUri && (
        <div className="image-controls">
          <MdDelete onClick={onDelete} />
        </div>
      )}
    </div>
  )
}
