import React from 'react'
import * as CVTypes from '../types'
import { ExperienceList } from './ExperienceList'
import { PublicationList } from './PublicationList'
import { EducationList } from './EducationList'
import { SkillList } from './SkillList'
import { CustomItemList } from './CustomItemList'

export type SectionId = 'skills' | 'experience' | 'publications' | 'education' | string

export const EditorSection: React.FC<{
  cv: CVTypes.CV
  setUpdatedCv: (value: CVTypes.CV) => void
  sectionId: SectionId
}> = ({ cv, setUpdatedCv, sectionId }) => {
  switch (sectionId) {
    case 'skills':
      return (
        <SkillList
          items={cv.skills}
          onChange={(skills) => {
            setUpdatedCv({ ...cv, skills })
          }}
        />
      )
    case 'skill-highlights':
      return (
        <div>
          <h5>Skill Highlights</h5>
          <p className="text-muted">This section will use entries from &quot;Skills&quot; section which have &quot;Highlights&quot; filled out.</p>
        </div>
      )
    case 'experience':
      return (
        <ExperienceList
          items={cv.experience}
          onChange={(experience) => {
            setUpdatedCv({ ...cv, experience })
          }}
        />
      )
    case 'publications':
      return (
        <PublicationList
          items={cv.publications}
          onChange={(publications) => {
            setUpdatedCv({ ...cv, publications })
          }}
        />
      )
    case 'education':
      return (
        <EducationList
          items={cv.education}
          onChange={(education) => {
            setUpdatedCv({ ...cv, education })
          }}
        />
      )
    default:
      if (cv.customSections) {
        const cs = cv.customSections.find((cs) => cs.id === sectionId)

        if (cs) {
          return (
            <CustomItemList
              onDelete={() => {
                if (cv.customSections) {
                  setUpdatedCv({ ...cv, customSections: cv.customSections.filter((c) => c.id !== sectionId) })
                }
              }}
              onTitleChange={(title) => {
                if (cv.customSections) {
                  setUpdatedCv({
                    ...cv,
                    customSections: cv.customSections.map((c) => (c.id === sectionId ? { ...c, title } : c)),
                  })
                }
              }}
              title={cs.title}
              items={cs.items}
              onChange={(newItems) => {
                const updatedSection = { ...cs, items: newItems ? newItems : [] }
                if (cv.customSections) {
                  setUpdatedCv({ ...cv, customSections: cv.customSections.map((c) => (c.id === sectionId ? updatedSection : c)) })
                }
              }}
            />
          )
        }
      }
  }

  return <div></div>
}
