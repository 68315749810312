export const calcAvailableHeight = (totalHeight: number, items: { height: number }[], position: number) => {
  if (totalHeight > 0) {
    return (
      totalHeight -
      items
        .map((i) => i.height)
        .slice(0, position)
        .reduce((a, b) => a + b, 0)
    )
  }
  return 0
}

export const isReadyToRender = (items: { hasRemaining: boolean }[], dependsOn: number[]) => {
  if (dependsOn.length === 0) return true
  const dependencies = dependsOn.map((d) => items[d]).filter((d) => d !== undefined)

  if (dependencies.find((d) => d.hasRemaining)) {
    return false
  }

  if (dependencies.length === dependsOn.length) {
    return true
  }

  return false
}

export const areAllFinished = (items: { hasRemaining: boolean }[], dependsOn: number[]) => {
  const dependencies = dependsOn.map((d) => items[d]).filter((d) => d !== undefined)

  if (dependencies.find((d) => d.hasRemaining)) {
    return true
  }

  if (dependencies.length === dependsOn.length) {
    return true
  }

  return false
}
